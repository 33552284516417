import { ComponentType } from '@angular/cdk/overlay';

import { ICommonPopupData } from './interfaces/popup-data.interface';
import { ICommonActionsDropdownComponentActions } from '../context-menu/actions-dropdown.component/common-actions-dropdown.interfaces';
import { CommonBaseComponent } from '@CaseOne/Common/base_component/base.component';

export class CommonPopupOptions<D = any, U extends CommonBaseComponent = CommonBaseComponent, R = any> {
	component: ComponentType<D>;
	headerComponent?: ComponentType<U>;
	data?: ICommonPopupData<R> | null = null;
	popupOverlayClasses?: string = '';
	popupClasses?: string = '';
	onLoadData?: Promise<any>;
	showExternalCloseIcon?: boolean = false;
	showHeader?: boolean = true;
	closeOnNext?: boolean = false;  // close current popup after opening next
	closePrev?: boolean = false;  // close previous popup in queue
	isOverlapped?: boolean;
	withControlOpenState?: boolean = true;

	/**
	 * @deprecated `contextMenuItems`
	 * Use `this.popup.containerInstance.setContextMenuItems` function in popup component
	 */
	contextMenuItems?: ICommonActionsDropdownComponentActions = [];
}
