import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash';

@Pipe({
	name: 'commonRemoveHtmlTagsPipe',
})
export class CommonRemoveHtmlTagsPipe implements PipeTransform {
	transform (str: string) {
		return !isNil(str) ? String(str).replace(/<[^>]+>/gm, '') : '';
	}
}
