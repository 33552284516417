import { isObject } from 'lodash';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { Injectable } from '@angular/core';

@Injectable()
export class CommonTranslationCompiler extends TranslateMessageFormatCompiler {
	compileTranslations (translations, lang) {
		for (const key in translations) {
			if (translations.hasOwnProperty(key)) {
				// For common_html_editor
				if (!isObject(translations[key])) {
					translations[key] = this.resolveReferences(translations[key], translations, key);
				}
				// else do nothing
			}
		}

		window.LocaleData.translations = translations;

		return super.compileTranslations(translations, lang);
	}

	private resolveReferences (value: string, translations: any, key: string): string {
		const matches = value.match(/@:(\S+)/);

		if (matches) {
			const [, foundKey] = matches;

			if (translations[foundKey]) {
				return this.resolveReferences(translations[foundKey], translations, foundKey);

			} else {
				console.error(`CommonTranslationCompiler#resolveReferences not found translate for key '${foundKey}' in key '${key}'`);
			}
		}

		return value;
	}
}
