import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'commonTwoDigitsNumber',
})
export class CommonTwoDigitsNumberPipe implements PipeTransform {
	transform (num: number, placeholder = '0') {
		return (placeholder + num).slice(-2);
	}
}
