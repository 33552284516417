import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'commonCapitalize',
})
export class CommonCapitalizePipe implements PipeTransform {
	transform(input: string) {
		return (!!input && 0 < input.length) ? input.charAt(0).toUpperCase() + input.substr(1) : '';
	}
}
