import { Injectable } from '@angular/core';
import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse,
} from '@angular/common/http';
import {
	get,
	set,
} from 'lodash';
import {
	Observable,
	Observer,
} from 'rxjs';

@Injectable()
export class CommonRequestCountInterceptor implements HttpInterceptor {
	private requests: Array<HttpRequest<any>> = [];

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		this.requests.push(req);
		set(window, 'CO.pendingRequests', this.requests.length);

		return new Observable((observer: Observer<any>) => {
			// And subscribe to the original observable to ensure the HttpRequest is made
			const subscription = next.handle(req)
				.subscribe(
					(event) => {
						if (event instanceof HttpResponse) {
							this.decreaseCount(req);
							observer.next(event);
						}
					},
					(err) => { this.decreaseCount(req); observer.error(err); },
					() => { this.decreaseCount(req); observer.complete(); },
				);

			return () => {
				this.decreaseCount(req);
				subscription.unsubscribe();
			};
		});
	}

	private decreaseCount(req: HttpRequest<any>) {
		const i = this.requests.indexOf(req);

		if (i >= 0) {
			this.requests.splice(i, 1);
			set(window, 'CO.pendingRequests', this.requests.length);
		}
	}
}
