import {
	defaults,
	cloneDeep,
} from 'lodash';

import { ICommonResourceAction, ICommonResourceHeaders } from './interfaces/resource-action.interface';
import { COMMON_HTTP_METHOD_TYPE } from '../interfaces/common-http-method-type.enum';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, share, switchAll, tap } from 'rxjs/operators';
import { CommonBaseService } from '../common-base-service.class/common-base-service.class';
import { Injectable } from '@angular/core';

const MANUAL_REQUEST_REJECT_ERROR = 'MANUAL_REQUEST_REJECT_ERROR';

// Base class for creating resource services
@Injectable()
export class CommonResource extends CommonBaseService {
	protected httpClient = this.injector.get(HttpClient);
	private requestsMap = new Map();

	$action<Query, Response> (
		name: string,
		query: Query,
		options: ICommonResourceAction<Query, Response>,
		headers?: ICommonResourceHeaders,
	): Observable<Response> {
		options = defaults(cloneDeep(options), {
			method: COMMON_HTTP_METHOD_TYPE.GET,
			query: {},
			cancelPrevRequest: false,
		});

		query = defaults(cloneDeep(query), options.query);

		let resultHeaders = null;

		if (options.headers) {
			resultHeaders = options.headers;
		}
		if (headers) {
			resultHeaders = {
				...resultHeaders,
				...headers,
			};
		}

		const passDataToRequestThroughBody = options.passDataToRequestThroughBody ||
			([COMMON_HTTP_METHOD_TYPE.POST, COMMON_HTTP_METHOD_TYPE.PUT].includes(options.method) && !options.passDataToRequestThroughParams);
		const passDataToRequestThroughParams = options.passDataToRequestThroughParams ||
			([COMMON_HTTP_METHOD_TYPE.GET, COMMON_HTTP_METHOD_TYPE.DELETE].includes(options.method) && !options.passDataToRequestThroughBody);
		const createRequest = (): Observable<Response> => {
			return this.httpClient
				.request<Response>(
					options.method,
					options.url,
					{
						responseType: 'json',
						headers: resultHeaders ? new HttpHeaders(resultHeaders) : null,
						params: passDataToRequestThroughParams
							? new HttpParams({
								// @ts-ignore
								fromObject: query,
							})
							: null,
						body: passDataToRequestThroughBody ? query : null,
					},
				);
		};

		let response$;

		if (options.cancelPrevRequest) {
			const prevRequest$: BehaviorSubject<Observable<Response>> = this.requestsMap.get(name);

			if (prevRequest$) {
				prevRequest$.error(MANUAL_REQUEST_REJECT_ERROR);
				prevRequest$.complete();
			}

			const newRequest: BehaviorSubject<Observable<Response>> = new BehaviorSubject<Observable<Response>>(null);
			response$ = newRequest.pipe(
				switchAll(),
				catchError((err) => {
					return err === MANUAL_REQUEST_REJECT_ERROR ? of(null) : throwError(err);
				}),
				filter((value) => !!value),
				tap(() => {
					newRequest.complete();
					this.requestsMap.delete(name);
				}),
			);

			this.requestsMap.set(name, newRequest);

			newRequest.next(createRequest());
		} else {
			response$ = createRequest();
		}

		return response$.pipe(share());
	}
}
