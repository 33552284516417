export interface ICommonAnimateStylesParams {
	duration: number;
	element: HTMLElement,
	styles: {
		[style: string]: [number, number, string?],
	}
}

export async function commonAnimateStyles (params: ICommonAnimateStylesParams): Promise<void> {
	const savedComputedStyle = window.getComputedStyle(params.element);
	const transitionStyleNames = [];

	for (const [styleName, [start, end, postfix]] of Object.entries(params.styles)) {
		transitionStyleNames.push(styleName);
		params.element.style[styleName] = (postfix) ? start + postfix : start;
	}
	params.element.style.transition = transitionStyleNames
		.map((styleName) => styleName ? `${ styleName.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase() } ${ params.duration / 1000 }s linear` : '')
		.join(', ');

	// waiting for browser rendering new styles
	await new Promise((res) => {
		window.requestAnimationFrame(res);
	});

	for (const [styleName, [start, end, postfix]] of Object.entries(params.styles)) {
		params.element.style[styleName] = (postfix) ? end + postfix : end;
	}

	// waiting animation time over
	await new Promise((res) => setTimeout(res, params.duration));

	params.element.style.transition = savedComputedStyle.transition;
}
