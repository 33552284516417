import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';

import { CommonFiltersToUrlService } from './filters_to_url.service';
import { CommonFiltersFromUrlService } from './filters_from_url.service';

@NgModule({
	providers: [
		CommonFiltersToUrlService,
		CommonFiltersFromUrlService,
	],
	imports: [
		CommonModule,
		UIRouterModule.forChild(),
	],
})
export class CommonUrlFiltersModule {
}
