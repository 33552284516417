// New version src/Common/controls/textfield/textfield.component.ts CommonTextfieldComponent
// Old version src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/controls/common_controls_textfield_module/ctrl_textfield_component_service.jsx CtrlTextfieldComponent

import {
	Component,
	Input,
	ElementRef,
	ViewChild,
	SimpleChanges,
	forwardRef,
	ChangeDetectionStrategy,
	ViewEncapsulation,
	SkipSelf,
} from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
	forEach,
	isString,
	isNumber,
} from 'lodash';
import * as Inputmask from 'inputmask';

import { commonBrowserService } from '../../browser/common-browser.service';
import { commonUtilitiesCoreService } from '../../utilities/core.service';
import { COMMON_KEYBOARD_KEY } from '../../constants/keyboard.constant';
import { TCallbackFunc, THTMLInputType } from '../../interfaces/core';
import { CommonDebugComponent } from '../control/debug/debug.decorator';
import { CommonControlComponent } from '../control/control.class';
import { COMMON_THROTTLE_MILLISECONDS } from '../control/throttler_milliseconds.constant';
import { COMMON_HOT_KEYS } from '../control/hot_keys.constant';
import { COMMON_SUGGEST_OFF_PROPERTY } from '../control/autocomplete_off_property.constant';


export type TCommonTextfieldModel = string | number;

@CommonDebugComponent({
	name: 'common-textfield',
	designLink: 'https://app.zeplin.io/project/591460ff7793c56928756817/screen/594a79f173e050bcc42578c8',
	description: 'Text field for forms',
	testsFile: require('!raw-loader!./textfield.tests.spec.ts'),
	props: {
		model: {
			type: 'string',
			description: 'Model',
			defaultValue: 'Custom text',
		},
		isDisabled: {
			type: 'boolean',
			description: 'Control disabling',
			defaultValue: false,
		},
		isReadonly: {
			type: 'boolean',
			description: 'Controls readonly state',
			defaultValue: false,
		},
		inputMask: {
			type: 'string',
			description: 'Mask for textfield (ex. casem-numeric)',
			defaultValue: '',
		},
		inputMaskParams: {
			type: 'interface',
			description: 'Params for inputmask',
			defaultValue: {
				max: 10,
			},
		},
		inputMaskUnmaskedValue: {
			type: 'boolean',
			description: 'Model is masks value, not masks text',
			defaultValue: true,
		},
		placeholder: {
			type: 'string',
			description: 'Textfield placeholder',
			defaultValue: 'Placeholder',
		},
		floatLabel: {
			type: 'string',
			description: 'Label above placeholder',
			defaultValue: 'FloatLabel',
		},
		autocomplete: {
			type: 'string',
			description: 'Browsers automplete mode',
			defaultValue: 'off',
		},
		name: {
			type: 'string',
			description: 'Name in form',
			defaultValue: 'Name',
		},
		type: {
			type: 'string',
			description: 'Type of imput (THTMLInputType)',
			defaultValue: 'text',
		},
		iconClasses: {
			type: 'string',
			description: 'CSS classes for icon',
			defaultValue: '',
		},
		hideCount: {
			type: 'boolean',
			description: 'Hides the number of remaining letters',
			defaultValue: false,
		},
		ctrlClasses: {
			type: 'string',
			description: 'CSS classes for control',
			defaultValue: 'b-textfield--border_bottom',
		},
		hasClearIcon: {
			type: 'boolean',
			description: 'Hides clear icon',
			defaultValue: false,
		},
		hasDebounce: {
			type: 'boolean',
			description: 'Delayed model changing',
			defaultValue: false,
		},
		isUrl: {
			type: 'boolean',
			description: 'Showed text as url',
			defaultValue: false,
		},
		parseUrl: {
			type: 'function',
			description: 'Function for parsing value to url',
			defaultValue: null,
		},
		isLoading: {
			type: 'boolean',
			description: 'Showed loader',
			defaultValue: false,
		},
		hasLoaderDelay: {
			type: 'boolean',
			description: 'Delay berfore loader showed',
			defaultValue: false,
		},
		isUseHint: {
			type: 'boolean',
			description: 'Hint on not focused fields',
			defaultValue: false,
		},
		maxLength: {
			type: 'number',
			description: 'Max letters in field',
			defaultValue: undefined,
		},
		onFocus: {
			type: 'function',
			description: 'Focus event',
			defaultValue: null,
		},
	},
	states: {
		isFocused: {
			type: 'boolean',
			description: 'Control is focused',
		},
		isHovered: {
			type: 'boolean',
			description: 'Cursor is over control',
		},
		value: {
			type: 'string',
			description: 'Value in first cycle value -> model -> value',
		},
		inputValue: {
			type: 'string',
			description: 'Value in second cycle. Used for set value in input',
		},
		isInputMaskInitialized: {
			type: 'boolean',
			description: 'Indicate than input mask have been initialized. Dont correctly runing again',
		},
		charsLeft: {
			type: 'number',
			description: 'Amount of remained characters in string',
		},
		cancelSetModel: {
			type: 'boolean',
			description: 'There is awaiting before value writed in model',
			get: (component) => !!component.cancelSetModel,
			set: () => void(0),
		},
		isHintOn: {
			type: 'boolean',
			description: 'Hint on',
		},
		hintValue: {
			type: 'string',
			description: 'Hint text',
		},
		isValid: {
			type: 'boolean',
			description: 'Control is valid',
		},
		isEmpty: {
			type: 'boolean',
			description: 'Control is empty',
		},
	},
})
@Component({
	selector: 'common-textfield',
	templateUrl: './textfield.component.pug',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./textfield.component.sass'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CommonTextfieldComponent),
			multi: true,
		},
	],
	encapsulation: ViewEncapsulation.None,
	viewProviders: [
		{
			provide: ControlContainer,
			useFactory: (container: ControlContainer) => container,
			deps: [[new SkipSelf(), ControlContainer]],
		},
	],
})
export class CommonTextfieldComponent extends CommonControlComponent<TCommonTextfieldModel> {
	@Input() inputMask: string = '';
	@Input() inputMaskParams: any = null; // interface
	@Input() inputMaskUnmaskedValue: boolean = false;
	@Input() autocomplete: string = COMMON_SUGGEST_OFF_PROPERTY;
	@Input() type: THTMLInputType = 'text';
	@Input() iconClasses: string = '';
	@Input() hideCount: boolean = true;
	@Input() hasClearIcon: boolean = false;
	@Input() hasDebounce: boolean = false;
	@Input() isUrl: boolean = false;
	@Input() parseUrl: (value: string) => string;
	@Input() isLoading: boolean = false;
	@Input() hasLoaderDelay: boolean = false;
	@Input() maxLength: number;
	@Input() commonMaxForMessage: number;
	@Input() commonMinForMessage: number;
	@Input() commonMaxLengthForMessage: number;
	@Input() commonMinLengthForMessage: number;
	@Input() withRightIcon: boolean;

	@Input() titleExternal: string;

	@Input() isUseHint: boolean = false;
	@Input() onHint: (model: TCommonTextfieldModel) => string;

	value: string = '';
	titleValue: string = '';
	inputValue: string = '';
	hintValue: string = '';
	isHintOn: boolean = false;
	charsLeft: number = 0;

	@ViewChild('input', { static: true }) public inputRef: ElementRef;
	protected idKey: string = 'textfield_';
	protected isInputMaskInitialized: boolean = false;
	protected inputMaskEventRemover: () => void;
	protected formatterInput: HTMLInputElement;
	protected cancelSetModel: () => void;

	private isOnFocusEmitDisabled = false;

	ngOnInit() {
		super.ngOnInit();

		this.value = this.formattedModel(this.model);
		this.setInputValue(this.value);
		this.charsLeft = this.getCharsLeft(this.value);

		if (this.isNeedEnableHint(this.model)) {
			this.hintValue = this.onHint(this.model);
			this.isHintOn = true;
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		super.ngOnChanges(changes);

		if (changes.model) {
			this.updateModel(changes.model.previousValue, changes.model.currentValue);
		}

		if (changes.inputMask) {
			// remove old Mask
			if (this.isInputMaskInitialized) {
				const input = this.inputRef.nativeElement;

				this.inputMaskEventRemover();
				this.inputMaskEventRemover = null;
				input.inputmask.remove();
				this.isInputMaskInitialized = false;
			}

			// Set new Mask
			if (changes.inputMask.currentValue) {
				this.initInputMask();
			}
		}

		if (changes.titleExternal) {
			this.setTitleValue();
			this.runUpdate();
		}
	}

	writeValue(value: any): void {
		this.updateModel(this.model, value);
		this.onModelChange();
		this.runUpdate();
	}

	isWithRightIcon(): boolean {
		return this.hasClearIcon || this.isUrl || this.isLoading || this.withRightIcon;
	}

	isWithCount(): boolean {
		return !!this.maxLength && !this.hideCount && !this.isDisabled;
	}

	// With zonejs
	onFocusHandler(event: Event): void {
		if (this.isDisabled || this.isOnFocusEmitDisabled) {
			return;
		}

		this.initInputMask();
		super.onFocusHandler(event);
		this.onFocus.emit(event);
		this.disableHint();
	}

	// Blur with zonejs
	onBlurHandler(event: Event): void {
		if (this.isDisabled) {
			return;
		}

		let model = this.model;

		// Cancel debounce and set model
		if (this.cancelSetModel) {
			this.cancelSetModel();
			model = this.parseValue(this.value);
			this.setModel(model);
		}

		super.onBlurHandler(event);

		this.timeout(() => {
			this.checkHint(model);
		});
	}

	checkHint(model = this.model) {
		if (this.isNeedEnableHint(model)) {
			this.enableHint(model);
			this.runUpdate();
		}
	}

	// Without zonejs
	onKeyDownInput(event: KeyboardEvent): void {
		if (this.isDisabled) {
			return;
		}

		// const normalizeEvent = this.getNormalizeEvent(event);

		if (event.which === COMMON_HOT_KEYS.ENTER || event.key === COMMON_KEYBOARD_KEY.ENTER) {
			this.onEnter.emit(event);
		}

		this.onKeyDown.emit(event);
	}

	// Without zonejs
	onKeyUpInput(event: KeyboardEvent): void {
		// For input mask
		this.onChangeInput(this.inputRef.nativeElement.value, event);

		if (!this.isDisabled) {
			this.onKeyUp.emit(event);
		}
	}

	// With zonejs
	onMouseUpInput(event: MouseEvent): void {
		if (commonBrowserService.browser.ie && event.which === COMMON_HOT_KEYS.RIGHT_MOUSE) {
			this.focus();
		}
	}

	// With zonejs
	onClickHandler(event?: MouseEvent): void {
		if (!this.isDisabled) {
			this.onClick.emit(event);
		}
	}

	// Without zonejs
	onChangeInput(value: string, event: Event) {
		if (this.isDisabled) {
			return;
		}

		const oldValue = this.value;

		if (value === oldValue) {
			return;
		}

		// Saves async access to variables of an event (react)
		// event.persist && event.persist();

		const parseValue = this.parseValue(value);

		this.setValue(value, parseValue);

		if (this.isInputMaskInitialized && this.inputMask) {
			const inputmask = this.inputRef.nativeElement.inputmask;
			const unmaskAsNumber = inputmask.option('unmaskAsNumber');

			// Save '-' when user writes the first character
			if (!(unmaskAsNumber && oldValue === '' && value === '-')) {
				this.debounceSetModel(parseValue);
			}
		} else {
			this.debounceSetModel(parseValue);
		}
	}

	selectAll() {
		this.focus();

		if (this.value) {
			this.inputRef.nativeElement.setSelectionRange(0, this.value.length);
		}
	}

	protected updateModel(oldModel, newModel) {
		const hasValueInModels = commonUtilitiesCoreService.hasValue(newModel) || commonUtilitiesCoreService.hasValue(oldModel);

		if (hasValueInModels && newModel !== oldModel) {
			// Filter the placeholder value for IE
			if (this.isInputMaskInitialized && this.inputMask) {
				const inputmask = this.inputRef.nativeElement.inputmask;
				const unMaskValue = inputmask.unmaskedvalue();

				if (unMaskValue !== newModel) {
					const newValue = this.formattedModel(newModel);
					const placeholder = inputmask.option('placeholder');

					if (!(placeholder && newValue === '' && unMaskValue === '' && this.value === placeholder)) {
						this.setValue(newValue, newModel);
					}
				} else {
					this.model = newModel;
				}
			} else {
				const newValue = this.formattedModel(newModel);
				this.setValue(newValue, newModel);
			}

			if (this.isNeedEnableHint(newModel)) {
				this.enableHint(newModel);
			} else {
				this.disableHint();
			}
		}
	}

	protected focus() {
		this.inputRef.nativeElement.focus();
	}

	protected initInputMask() {
		if (!this.isInputMaskInitialized && this.inputMask) {
			const input = this.inputRef.nativeElement;

			// https://jira.parcsis.org/browse/CASEM-55856
			// IE Workaround: Setting of inputmask triggers focus event on input that propagates to upper wrappers
			// https://github.com/RobinHerbots/Inputmask/issues/2358 - issue with similar behavior
			// Solution: disable onFocus emit for period of initializing mask and call blur on input
			if (commonBrowserService.browser.ie) {
				this.isOnFocusEmitDisabled = true;

				Inputmask(this.inputMask, this.inputMaskParams).mask(input);

				this.timeout(() => {
					this.isOnFocusEmitDisabled = false;
					input.blur();
				});
			} else {
				Inputmask(this.inputMask, this.inputMaskParams).mask(input);
			}

			const removeFormEventRemover = this.willUnbind(() => {
				// When run "inputmask.remove()" "input" is not have "form" field,
				// so inputmask can't remove "submit" handler
				// https://jira.parcsis.org/browse/CASEM-26200
				const events = input.inputmask.events;
				const form = input.form;

				forEach(events, (eventHandlers, eventName) => {
					if (-1 < ['submit', 'reset'].indexOf(eventName) && input.form) {
						while (0 < eventHandlers.length) {
							const eventHandler = eventHandlers.pop();
							form.removeEventListener(eventName, eventHandler);
						}

						delete events[eventName];
					}
				});

				// timeout so that the events on submit do not break, then remove the mask
				// https://jira.parcsis.org/browse/CASEM-22727
				this.timeout(() => {
					if (input.inputmask) {
						input.inputmask.remove();
					}
				});
			});

			// Input mask beak event change
			// in IE without incomplete the garbage gets to model

			// The 'blur' event is needed to update numbers in the model, if inputmask params has min, max values
			// https://jira.parcsis.org/browse/CASEM-23473
			// const removeEventRemover = this.willUnbind(
			// 	this.onEvent(input, 'keyup keydown paste cut incomplete blur', (event) => this.onChangeInput(input.value, event)),
			// );

			this.inputMaskEventRemover = () => {
				removeFormEventRemover();
				// removeEventRemover(true);
			};

			this.isInputMaskInitialized = true;

			this.setValue(this.formattedModel(this.model), this.model);
		}
	}

	// Formats value of model till a line of an input
	protected formattedModel(model: TCommonTextfieldModel): string {
		const maxLength = this.maxLength;
		let result: string = '';

		if (isString(model)) {
			result = model;
		}

		if (isNumber(model) && isFinite(model)) {
			result = model.toString();
		}

		result = result.trim();

		if (maxLength && result.length > maxLength) {
			result = this.limitTo(result, maxLength);
		}

		// In IE in case of an initialization doesn't fulfill input a mask
		// Blank lines not to lay down, behaves inadequately
		if (result && this.inputMask) {
			const inputmask = new Inputmask(this.inputMask, this.inputMaskParams);
			const autoUnmask = inputmask.option('autoUnmask');
			const radixPoint = inputmask.option('radixPoint');

			if (!autoUnmask || !this.isInputMaskInitialized) {
				// Inputmask have incorrect formating for `format` method
				// Because using default mechanism
				if (!this.formatterInput) {
					this.formatterInput = document.createElement('input');
				}

				// CASEM-63584: radixPoint in the value must match the inputmask settings radixPoint
				// https://github.com/RobinHerbots/Inputmask/blob/5.x/README_numeric.md#inputtype
				if (radixPoint) {
					result = result.replace('.', radixPoint);
				}

				this.formatterInput.value = result;
				inputmask.mask(this.formatterInput);
				result = (this.formatterInput as any).inputmask.maskset.buffer.join('');
				(this.formatterInput as any).inputmask.remove();
			}
		}

		return result;
	}

	// input value -> model
	protected parseValue(inputValue: string): TCommonTextfieldModel {
		const maxLength = this.maxLength;
		let value: TCommonTextfieldModel = inputValue.trim();

		if (maxLength) {
			value = this.limitTo(value, maxLength);
		}

		if (this.isInputMaskInitialized && this.inputMask) {
			const input = this.inputRef.nativeElement;
			const inputmask = input.inputmask;
			const unmaskAsNumber = inputmask.option('unmaskAsNumber');
			const autoUnmask = inputmask.option('autoUnmask');
			const unMaskValue = inputmask.unmaskedvalue();
			const placeholder = inputmask.option('placeholder');

			// Filter the placeholder value for IE
			if (placeholder && unMaskValue === '' && value === placeholder) {
				return '';
			}

			if (this.inputMaskUnmaskedValue || autoUnmask || unmaskAsNumber) {
				value = unMaskValue;

				if (isString(value)) {
					value = value.trim();
				}

				// Because of check (unmaskedValue = opts.onUnMask(bufferValue, unmaskedValue, opts) || unmaskedValue)
				// for numerical masks method unmaskedvalue return 0 as string, it is necessary to put this crutch
				// https://github.com/RobinHerbots/jquery.inputmask/blob/3.x/js/inputmask.js#L1736
				if (unmaskAsNumber && value === '0') {
					value = 0;
				}

				// In IE return '0' with empty sting in input
				if (!input.value) {
					value = null;
				}
			}
		}

		return value;
	}

	protected limitTo(val: string, limit: number): string {
		return val.substr(0, limit);
	}

	protected parseUrlFunc(url: string): string {
		if (this.parseUrl) {
			url = this.parseUrl(url);
		}

		if (!url.match(/^[a-zA-Z\+]+:/)) {
			url = 'http://' + url;
		}

		return url;
	}

	// Quantity of the remained free characters
	protected getCharsLeft(value: string): number {
		const maxLength = this.maxLength;

		if (maxLength && !this.hideCount) {
			return maxLength - value.length;
		} else {
			return 0;
		}
	}

	protected getIsEmpty(): boolean {
		const value = this.formattedModel(this.model);

		return !this.hasValue(value);
	}

	protected isNeedEnableHint(model: TCommonTextfieldModel): boolean {
		return this.isUseHint && !!model;
	}

	// The single method which shall change value in state
	protected setValue(value: string, model: TCommonTextfieldModel): void {
		this.value = value;
		this.model = model;
		this.charsLeft = this.getCharsLeft(value);

		this.setInputValue(value);
		this.setTitleValue();
	}

	// Fix for inputmask
	protected setInputValue(value: string): void {
		if (value !== this.inputRef.nativeElement.value) {
			this.inputValue = value;
			this.inputRef.nativeElement.value = value;

			if (!(commonBrowserService.browser.ie && this.isDisabled)) {  // CASEM-68023: IE11 doesn't set inputRef.nativeElement.value when control is disabled
				if (this.inputRef.nativeElement.value !== value) {
					this.model = this.inputRef.nativeElement.value;

					this.zone.run(() => {
						this.modelChange.emit(this.model);

						if (this.onChangeForValueAccessor) {
							this.onChangeForValueAccessor(this.model);
						}
					});
				}
			}
		}
	}

	protected setTitleValue(): void {
		this.titleValue = this.isHintOn && !this.isFocused ? this.hintValue : this.value;
	}

	protected setModel(model: TCommonTextfieldModel): void {
		this.zone.run(() => {
			this.modelChange.emit(model);
			this.emitOnChange(model);
		});
	}

	protected debounceSetModel(model: TCommonTextfieldModel): void {
		this.model = model;
		this.onModelChange();

		if (this.hasDebounce) {
			if (this.cancelSetModel) {
				this.cancelSetModel();
			}

			this.cancelSetModel = this.timeout(
				() => this.setModel(model),
				COMMON_THROTTLE_MILLISECONDS,
				() => this.cancelSetModel = null,
			);
		} else {
			this.setModel(model);
		}
	}

	// Click with zonejs
	protected clearModel(): void {
		// Don't forget about debounce
		if (this.cancelSetModel) {
			this.cancelSetModel();
		}

		this.setValue('', '');
		this.setModel('');
		this.onModelChange();
	}

	protected enableHint(model: TCommonTextfieldModel): void {
		const hintValue = this.onHint(this.model);

		this.isHintOn = true;
		this.hintValue = hintValue;
		this.setTitleValue();
	}

	protected disableHint(): void {
		if (this.isHintOn) {
			this.isHintOn = false;
			this.hintValue = '';
			this.setTitleValue();
		}
	}

	protected mouseDownPreventDefault(
		event: MouseEvent,
		callback: TCallbackFunc = () => void(0),
	): void {
		// const normalizeEvent = this.getNormalizeEvent(event);

		if (event.which === COMMON_HOT_KEYS.LEFT_MOUSE && !this.isDisabled) {
			// Save focus in input
			event.preventDefault();
			callback();
		}
	}

	// Mousedown with zonejs
	protected onClickClearButton(event: MouseEvent): void {
		if (this.isDisabled) {
			return;
		}

		this.mouseDownPreventDefault(event, () => this.clearModel());
	}

	protected onUrlClickHandler(event: MouseEvent): void {
		return;
	}
}
