import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonAutofocusDirective } from './common_autofocus.directive';

@NgModule({
	imports: [
		CommonModule,
	],
	declarations: [
		CommonAutofocusDirective,
	],
	exports: [
		CommonAutofocusDirective,
	],
})
export class CommonAutofocusModule {}
