// New version src/Common/bootstrap/bootstrap.service.ts CommonBootstrapService
// Old version src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/services/common_bootstrap_service.ts CommonBootstrapService

import {get} from 'lodash';
import { Injectable } from '@angular/core';

@Injectable()
export class CommonBootstrapService {
	private bootstrap;

	init (data): void {
		if (data['CaseMap.Modules.Main']) {
			this.bootstrap = data['CaseMap.Modules.Main'];
		} else {
			this.bootstrap = data;
		}
	}

	getBootstrap<T> (path?: string): T {
		return path ? get(this.bootstrap, path) : this.bootstrap;
	}
}
