import { ICommonResourceAction } from '../interfaces/resource-action.interface';
import { CommonResource } from '../resource.class';
import { ICommonResourceMethod } from '../interfaces/resource-method.interface';
import { each } from 'lodash';

export function CommonResourceAction<Query, Response> (
	options: ICommonResourceAction<Query, Response>,
) {

	// tslint:disable-next-line:only-arrow-functions
	return function (
		target: CommonResource,
		propertyKey: string,
	) {
		const actionMethod = function (query, headers) {
			each(options.queryFormatters, (formatter) => {
				query = formatter.bind(this)(query);
			});

			let response$ = this.$action(
				propertyKey,
				query,
				options,
				headers,
			);

			each(options.parsers, (parser) => {
				response$ = parser.bind(this)(response$);
			});

			return response$;
		} as ICommonResourceMethod<Query, Response>;

		target[propertyKey] = function (...args: any[]) {
			return actionMethod.apply(this, args);
		};
	};
}
