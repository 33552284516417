import {
	ChangeDetectionStrategy,
	Component,
	ViewEncapsulation,
} from '@angular/core';
import { COMMON_POPUP_CLASS_NAMES } from '../../constants/popup-class-names.constant';
import { CommonBaseComponent } from '@CaseOne/Common/base_component/base.component';

@Component({
	selector: 'common-popup-loader',
	templateUrl: './popup-loader.component.pug',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonPopupLoaderComponent extends CommonBaseComponent {
	public loaderClassName = COMMON_POPUP_CLASS_NAMES.OVERLAY_LOADER;
}
