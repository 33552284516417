// New version src/Common/controls/control/validation/validation.component.ts CommonValidationComponent
// Old version src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/widgets/validation_component_service.jsx ValidationComponent

import {
	ChangeDetectionStrategy,
	Component,
	Input,
	SimpleChanges,
	ViewEncapsulation,
} from '@angular/core';
import {
	NgModel,
	NgForm,
	FormControlDirective,
	FormGroupDirective,
} from '@angular/forms';
import {
	forEach,
	isBoolean,
	get,
	includes,
} from 'lodash';
import { merge } from 'rxjs';

import { CommonBaseComponent } from '../../../base_component/base.component';
import { DEFAULT_ERROR_MESSAGES } from './default_error_messages.constant';
import { COMMON_REQUIRED_ERROR_KEY } from '../../../control_validators/required/required.validator';
import { COMMON_REQUIRED_ID_ERROR_KEY } from '../../../control_validators/required-id.directive/common-required-id.validator';
import { COMMON_MULTIPLE_FIELD_REQUIRED_ERROR_KEY } from '../../../control_validators/multiple-field-required/multiple-field-required.directive';
import { COMMON_REQUIRED_GROUP_ERROR_KEY } from '../../../control_validators/common-required-group/common-required-group.validator';
import {
	COMMON_FILE_UPLOADING_ERROR_KEY
} from '@CaseOne/Common/files-uploading/file-uploading.validator/common-file-uploading.validator';
import { COMMON_MASK_ERROR_KEY } from '@CaseOne/Common/control_validators/mask/mask.validator';

declare const COMPONENT_SPECS_MODE: boolean;

export interface ICommonValidationComponentCustomErrors {
	[errorName: string]: string,
}

export interface ICommonValidationComponentError {
	message: string,
	context: {
		[key: string]: any,
	},
}

export type TCommonValidationComponentError = ICommonValidationComponentError | boolean;

@Component({
	selector: 'common-validation',
	templateUrl: './validation.component.pug',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: [
		'./validation.component.sass',
	],
	encapsulation: ViewEncapsulation.None,
})
export class CommonValidationComponent extends CommonBaseComponent {
	@Input() customErrors: ICommonValidationComponentCustomErrors;
	@Input() ngModelControl: NgModel;
	@Input() formControlDirective: FormControlDirective;
	@Input() formGroupDirective: FormGroupDirective;
	@Input() isShow: boolean = true;
	@Input() set commonMaxForMessage(max: number) {
		if (typeof(max) === 'number') {
			this.computedErrorMessages.max = this.instant('common.validation.max', { value: max });
		}
	}
	@Input() set commonMinForMessage(min: number) {
		if (typeof(min) === 'number') {
			this.computedErrorMessages.min = this.instant('common.validation.min', { value: min });
		}
	}
	@Input() set commonMinLengthForMessage(min: number) {
		if (typeof(min) === 'number') {
			this.computedErrorMessages.minLength = this.instant('common.validation.minlength', { value: min });
		}
	}
	@Input() set commonMaxLengthForMessage(max: number) {
		if (typeof(max) === 'number') {
			this.computedErrorMessages.maxLength = this.instant('common.validation.maxlength', { value: max });
		}
	}

	public message: string = '';

	private isModelControlInit = false;

	private computedErrorMessages = {
		max: null,
		min: null,
		maxLength: null,
		minLength: null,
	};

	ngOnChanges(changes: SimpleChanges) {
		super.ngOnChanges(changes);

		if (!this.isModelControlInit) {
			if (changes.ngModelControl && changes.ngModelControl.currentValue) {
				merge(
					this.ngModelControl.formDirective.ngSubmit,
					this.ngModelControl.update,
					this.ngModelControl.control.statusChanges,
				)
					.pipe(this.takeUntilDestroy())
					.subscribe(() => this.setMessage());

				this.isModelControlInit = true;
			}	else if (changes.formControlDirective && changes.formControlDirective.currentValue) {
				merge(
					(this.formGroupDirective.formDirective as FormGroupDirective).ngSubmit,
					this.formControlDirective.control.valueChanges,
					this.formControlDirective.control.statusChanges,
				)
					.pipe(this.takeUntilDestroy())
					.subscribe(() => this.setMessage());

				this.isModelControlInit = true;
			}
		}

		this.setMessage();
	}

	private getMessage(): string {
		let result = '';

		if ((this.ngModelControl || this.formControlDirective) && this.isShow) {
			const formDirective: NgForm = (this.ngModelControl || this.formGroupDirective).formDirective;
			const formControl = this.ngModelControl || this.formControlDirective;

			if (formDirective.submitted && !formControl.valid) {
				forEach(formControl.errors, (val: TCommonValidationComponentError, errorName) => {
					if (!val) {
						return;
					}

					let message = DEFAULT_ERROR_MESSAGES[errorName];
					let context = {};

					if (!isBoolean(val)) {
						context = val.context || context;
						message = val.message || message;
					}

					message = get(this.customErrors, errorName) || get(this.computedErrorMessages, errorName) || message;

					if (!message) {
						if (!includes([
							COMMON_REQUIRED_ERROR_KEY,
							COMMON_REQUIRED_ID_ERROR_KEY,
							COMMON_MULTIPLE_FIELD_REQUIRED_ERROR_KEY,
							COMMON_REQUIRED_GROUP_ERROR_KEY,
							COMMON_FILE_UPLOADING_ERROR_KEY,
							COMMON_MASK_ERROR_KEY,
						], errorName)) {
							console.warn('CommonValidationComponent#getMessage not have message for error', errorName, !COMPONENT_SPECS_MODE && this);
						}
						return;
					}

					message = this.instant(message, context);
					result = message || result;

					return false;
				});
			}
		}

		return result;
	}

	private setMessage() {
		this.message = this.getMessage();
		this.runUpdate();
	}
}
