import { NgModule } from '@angular/core';

import { CommonContentWrapperService } from './common-content-wrapper.service';
import { CommonContentWrapperComponent } from './common-content-wrapper.component/common-content-wrapper.component';

@NgModule({
	imports: [
	],
	providers: [
		CommonContentWrapperService,
	],
	declarations: [
		CommonContentWrapperComponent,
	],
	exports: [
		CommonContentWrapperComponent,
	],
	entryComponents: [
		CommonContentWrapperComponent,
	],
})
export class CommonContentWrapperModule {}
