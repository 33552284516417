import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonAppDataService, commonAppDataServiceFactory } from './app_data.service';

export function initCommonAppDataService (
	commonAppDataService: CommonAppDataService,
) {
	return () => {
		return commonAppDataService.init().toPromise();
	};
}

@NgModule({
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: initCommonAppDataService,
			multi: true,
			deps: [
				CommonAppDataService,
			],
		},
		{
			provide: CommonAppDataService,
			useFactory: commonAppDataServiceFactory,
		},
	],
})
export class CommonAppDataModule {}
