// New version src/Common/locale/locale.service.ts CommonLocaleService
// Old version src/CaseDotStar.ServicePackages.Frontend.Locale/scripts/locale/locale_run.js LocaleRun

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
	updateLocale,
	tz,
} from 'moment';
import 'moment-timezone';

import { CommonUserDataService } from '../user/user_data.service';
import { CommonGeneralSettingsService } from '../general_settings/general_settings.service';
import { BehaviorSubject } from 'rxjs';

export interface ICommonLocaleServiceData {
	localeKey: string,
	localeUrl?: string,
	translations?: {[key: string]: string},
	layoutTranslations?: {  // translations in layout for initial loader and bootstrap error
		Layout_Loading: string,
		Layout_Reconnect_StartMessage: string,
		Layout_Reconnect_Seconds: string,
		Layout_RunError: string,
		Layout_Error: string,
		Layout_Error_Message: string,
		Layout_ServerResponse: string,
	},
}

@Injectable({ providedIn: 'root' })
export class CommonLocaleService {
	readonly locale: string = window.LocaleData.localeKey;
	localeData: ICommonLocaleServiceData;

	private init$ = new BehaviorSubject<boolean>(false);

	constructor (
		private translateService: TranslateService,
		private commonUserDataService: CommonUserDataService,
		private commonGeneralSettingsService: CommonGeneralSettingsService,
	) {}

	init (): Promise<boolean> {
		return new Promise((resolve) => {  // for APP_INITIALIZER
			// For SandBox
			if (window.LocaleData && window.LocaleData.translations) {
				this.translateService.setTranslation(this.locale, window.LocaleData.translations);
			}

			this.localeData = window.LocaleData;
			this.translateService.setDefaultLang(this.locale);
			const observable = this.translateService.use(this.locale);

			observable.subscribe(() => {
				this.init$.next(true);
				resolve(true);
			});

			this.commonGeneralSettingsService.wait().subscribe((result) => {
				if (result) {
					this.setFormats();
				}
			});
		});
	}

	setFormats (): void {
		const timeFormat = this.commonGeneralSettingsService.getGeneralSettings('TimeFormat');
		const dateFormat = this.commonGeneralSettingsService.getGeneralSettings('DateFormat');
		const locale = this.locale === 'en' ? 'en-gb' : this.locale;

		if (timeFormat) {
			updateLocale(locale, {
				longDateFormat: {
					LT: timeFormat.Format,
					L: dateFormat.ShortFormat,
					LL: '',
					LLL: '',
					LLLL: '',
					LTS: '',
				},
				meridiemParse: new RegExp(timeFormat.PmDesignator + '|' + timeFormat.AmDesignator, 'gi'),
				isPM: (input) => {
					return input === timeFormat.PmDesignator;
				},
				meridiem: (hours) => {
					return hours > 11 ? timeFormat.PmDesignator : timeFormat.AmDesignator;
				},
				week: {
					doy: 1,
					dow: this.commonGeneralSettingsService.getGeneralSettings('DayOfWeek').IndexNumber + 1,
				},
			});
		}

		this.setTimeZone();
	}

	setTimeZone() {
		const userTimeZone = this.commonUserDataService.getDataByPath('TimeZone');

		if (userTimeZone) {
			tz.setDefault(userTimeZone.IanaId);
		}
	}

	instant (key: string, params?: {}): string {
		return this.translateService.instant(key, params);
	}

	wait(): BehaviorSubject<boolean> {
		return this.init$;
	}
}
