import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CommonAppDataService } from '../app_data/app_data.service';

@Injectable()
export class CommonSharedLinkInterceptor implements HttpInterceptor {
	private readonly appData = this.commonAppDataService.getData();

	constructor(
		private commonAppDataService: CommonAppDataService,
	) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		if (this.appData.LinkToken) {
			const cloneReq: HttpRequest<any> = req.clone({
				headers: req.headers.set('X-LinkTo', this.appData.LinkToken)
			});
			return next.handle(cloneReq);
		}
		return next.handle(req);
	}

}
