import {
	AbstractControl,
	NG_VALIDATORS,
	Validator,
} from '@angular/forms';
import { Directive } from '@angular/core';
import {
	isArray,
	find,
} from 'lodash';
import { ICommonFile } from '../uploading-files.service/common-uploading-files.intarfaces';

export const COMMON_FILE_UPLOADING_ERROR_KEY = 'fileUploading';

@Directive({
	selector: '[commonFileUploadingValidator]',
	providers: [{
		provide: NG_VALIDATORS,
		useExisting: CommonFileUploadingValidatorDirective,
		multi: true,
	}],
})
export class CommonFileUploadingValidatorDirective implements Validator {
	validate(control: AbstractControl): {[key: string]: any} | null {
		let files = [];

		if (control.value) {
			files = !isArray(control.value) ? [control.value] : control.value;
		}

		const foundUploadingFile = find(files, (file: ICommonFile) => file.isUploading);

		if (foundUploadingFile) {
			return {
				[COMMON_FILE_UPLOADING_ERROR_KEY]: true,
			};
		} else {
			return null;
		}
	}
}
