import { NgModule, Inject, Injector } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';

import { COMMON_APP_CONFIG, ICommonAppConfig } from '../../../Common/app/app.config.token';
import { CommonAppModule } from '../../../Common/app/app.module';

import { authenticationAppConfig } from './authentication.app_config.constant';
import { AuthenticationCommonModule } from './authentication.common.module';
import { AuthenticationComponent } from './authentication.component';
import { AuthenticationLoginComponent } from '../login/login.component';
import { AuthenticationResetPasswordComponent } from '../reset_password/reset_password.component';
import { authenticationRoutes } from '../routes/routes.constant';
import { authenticationRouterConfigFn } from '../routes/router.config';
import { AuthenticationUserService } from '../user/user_authentication.service';
import { AuthenticationRegistrationLinkComponent } from '../registration_link/registration_link.component';

@NgModule({
	declarations: [
		AuthenticationComponent,
		AuthenticationLoginComponent,
		AuthenticationResetPasswordComponent,
		AuthenticationRegistrationLinkComponent,
	],
	imports: [
		CommonAppModule,
		UIRouterModule.forRoot({
			otherwise: '/login',
			states: authenticationRoutes,
			useHash: true,
			config: authenticationRouterConfigFn,
		}),
		AuthenticationCommonModule,
	],
	entryComponents: [
		AuthenticationComponent,
	],
	providers: [
		{ provide: COMMON_APP_CONFIG, useValue: authenticationAppConfig },
		AuthenticationUserService,
	],
})
export class AuthenticationModule extends CommonAppModule {
	constructor (
		protected injector: Injector,
		@Inject(COMMON_APP_CONFIG) commonAppConfig: ICommonAppConfig,
	) {
		super(
			injector,
			commonAppConfig,
		);
	}
}
