import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonMaxValidatorDirective} from './max.directive';
import { CommonModule } from '@angular/common';

@NgModule({
	imports: [
		FormsModule,
		CommonModule,
	],
	exports: [
		CommonMaxValidatorDirective,
	],
	declarations: [
		CommonMaxValidatorDirective,
	],
})
export class CommonMaxValidatorModule {}
