import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors} from '@angular/forms';
import {isEmpty} from 'lodash';
import {CommonValidator} from '../common-validator';

export const COMMON_REQUIRED_ID_ERROR_KEY = 'commonRequiredId';
export interface ICommonRequiredIdValidatorContext {
	enabled?: boolean,
}

@Injectable()
export class CommonRequiredIdValidator extends CommonValidator {
	getValidator(context: ICommonRequiredIdValidatorContext = {}) {
		return (control: AbstractControl): ValidationErrors | null => {
			if (context.enabled === false || !control.value) {
				return null;

			} else {
				const valueId = control.value.Id || control.value.id;

				if (isEmpty(valueId)) {
					return { [COMMON_REQUIRED_ID_ERROR_KEY]: true };

				} else {
					return null;
				}
			}
		};
	}
}
