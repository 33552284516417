import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'commonSafeHtml',
})
export class CommonSafeHtmlPipe implements PipeTransform {

	constructor(
		private sanitizer: DomSanitizer,
	) {}

	transform(value: string = ''): SafeHtml {
		return this.sanitizer.bypassSecurityTrustHtml(this.sanitizer.sanitize(
			SecurityContext.HTML,
			value,
		));
	}

}
