import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { CommonValidator } from '../common-validator';


export const COMMON_BACK_END_ERROR_KEY = 'backEnd';


@Injectable({
	providedIn: 'root',
})
export class CommonBackEndValidator extends CommonValidator {
	getValidator() {
		return (control: AbstractControl): ValidationErrors | null => {
			return null;
		};
	}
}
