import { isString } from 'lodash';

export function getClosest (hostElement: HTMLElement, target: HTMLElement | string): HTMLElement {
	const selectedElements = isString(target) ?
		Array.from(document.querySelectorAll<HTMLElement>(target)) :
		[target];

	let closest;
	let current = hostElement;

	while (!closest && current && current.nodeType === Node.ELEMENT_NODE) {
		closest = selectedElements.find((element) => current === element);
		current = (current.parentElement || current.parentNode) as HTMLElement;
	}

	return closest;
}
