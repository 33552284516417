import {
	filter,
	mergeMap,
	tap,
} from 'rxjs/operators';
import {
	Observable,
	Subject,
} from 'rxjs';
import { Injector } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { OverlayRef } from '@angular/cdk/overlay';
import { uniqueId } from 'lodash';

import { CommonPopupContainerComponent } from './components/popup-container/popup-container.component';
import { ICommonPopupAction } from './interfaces/popup-action.interface';
import { COMMON_POPUP_ACTION_TYPE } from './constants/popup-action-type.constant';
import { COMMON_HOT_KEYS } from '../controls/control/hot_keys.constant';

export class CommonPopup {
	public injector: Injector;
	public componentInstance;
	public headerComponentInstance;
	public isHidden: boolean = false;
	public isOpen: boolean = false;
	public defaultCloseData = null;
	public withControlOpenState = true;

	private readonly onEventSubject = new Subject<ICommonPopupAction>();

	constructor(
		public overlayRef: OverlayRef,
		public containerInstance: CommonPopupContainerComponent,
	) {
		containerInstance.id = uniqueId();
		this.overlayRef.keydownEvents()
			.pipe(
				filter((event: KeyboardEvent) => event.keyCode === COMMON_HOT_KEYS.ESC),
			)
			.subscribe(() => {
				this.close();
			});
	}

	onEvent(eventType?: COMMON_POPUP_ACTION_TYPE): Observable<ICommonPopupAction> {
		return this.onEventSubject.asObservable()
			.pipe(filter((event) => !eventType || event.type === eventType));
	}

	onAfterClose(): Observable<ICommonPopupAction> {
		return this.onEvent()
			.pipe(
				filter((event) => event.type === COMMON_POPUP_ACTION_TYPE.AFTER_CLOSE),
			);
	}

	onSuccessful(): Observable<ICommonPopupAction> {
		return this.onEvent()
			.pipe(
				filter((event) => event.type === COMMON_POPUP_ACTION_TYPE.AFTER_CLOSE && event.payload && event.payload.IsSuccess),
			);
	}

	hide(): Observable<any> {
		this.onEventSubject.next({
			type: COMMON_POPUP_ACTION_TYPE.BEFORE_HIDE,
		});

		return this.containerInstance.hide()
			.pipe(
				tap(() => {
					this.isHidden = true;
					this.onEventSubject.next({
						type: COMMON_POPUP_ACTION_TYPE.AFTER_HIDE,
					});
				}),
			);
	}

	show() {
		this.onEventSubject.next({
			type: COMMON_POPUP_ACTION_TYPE.BEFORE_SHOW,
		});

		const show$ = this.containerInstance.show()
			.pipe(
				tap(() => {
					this.isHidden = false;
					this.onEventSubject.next({
						type: COMMON_POPUP_ACTION_TYPE.AFTER_SHOW,
					});
				}),
			);

		show$.subscribe();

		return show$;
	}

	emit(payload: any): void {
		this.onEventSubject.next({
			type: COMMON_POPUP_ACTION_TYPE.CUSTOM,
			payload,
		});
	}

	emitClickAdditionTitle(payload: any) {
		this.onEventSubject.next({
			type: COMMON_POPUP_ACTION_TYPE.CLICK_ADDITION_TITLE,
			payload,
		});
	}

	close(popupResult = this.defaultCloseData): Promise<any> {
		this.onEventSubject.next({
			type: COMMON_POPUP_ACTION_TYPE.BEFORE_CLOSE,
		});

		return this.containerInstance
			.hide()
			.pipe(
				mergeMap(() => {
					return this.containerInstance.close();
				}),
				tap(() => {
					this.isOpen = false;
					this.onEventSubject.next({
						type: COMMON_POPUP_ACTION_TYPE.AFTER_CLOSE,
						payload: popupResult,
					});
					this.onEventSubject.complete();
				}),
			)
			.toPromise();
	}

	render(): void {
		if (!this.isOpen) {
			const contentRef = this.containerInstance.attachComponentPortal(
				new ComponentPortal(this.containerInstance.options.component, null, this.injector),
			);

			this.componentInstance = contentRef.instance;

			if (this.containerInstance.options.headerComponent) {
				const headerContentRef = this.containerInstance.attachHeaderComponentPortal(
					new ComponentPortal(this.containerInstance.options.headerComponent, null, this.injector),
				);

				this.headerComponentInstance = headerContentRef.instance;
				this.headerComponentInstance.runUpdate();
			}

			this.isOpen = true;
			this.onEventSubject.next({
				type: COMMON_POPUP_ACTION_TYPE.AFTER_OPEN,
			});
		}
	}

	isCloseOnNext(): boolean {
		return this.containerInstance.options.closeOnNext;
	}
}
