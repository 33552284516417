import { NgModule } from '@angular/core';
import { CommonCheckInactiveService } from '@CaseOne/Common/common-check-inactive/services/common-check-inactive.service';
import { CommonCheckInactiveResourceService } from '@CaseOne/Common/common-check-inactive/services/common-check-inactive-resource.service';

@NgModule({
	providers: [
		CommonCheckInactiveService,
		CommonCheckInactiveResourceService,
	],
})
export class CommonCheckInactiveModule {}
