// Old version commonActionsDropdown src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/widgets/common_actions_dropdown_directive.js
// New version CommonActionsDropdownComponent src/Common/context-menu/actions-dropdown.component/common-actions-dropdown.component.ts

// The dropdown menu component, according to the options, forms the dropdown menu in a specific place in layouts

import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	HostBinding,
	HostListener,
	Injector,
	Input,
} from '@angular/core';
import {
	extend,
	get,
	cloneDeep,
} from 'lodash';

import { CommonDebugComponent } from '../../controls/control/debug/debug.decorator';
import { CommonContextMenuFactory } from '../context-menu.service/common-context-menu.factory';
import {
	COMMON_CONTEXT_MENU_ANIMATION_DIRECTIONS,
	COMMON_CONTEXT_MENU_ATTACHMENTS,
	COMMON_CONTEXT_MENU_EVENT_NAME,
	ICommonComponentWithContextMenu,
	ICommonContextMenuOptions,
} from '../context-menu.service/common-context-menu.interfaces';
import { CommonActionsDropdownOptionsListComponent } from './components/common-actions-dropdown-options-list/common-actions-dropdown-options-list.component';
import { CommonContextMenu } from '../context-menu.service/common-context-menu.service';
import {
	COMMON_ACTIONS_DROPDOWN_CONTENT_TYPE,
	COMMON_ACTIONS_DROPDOWN_CUSTOM_EVENT_TYPE,
	COMMON_ACTIONS_DROPDOWN_LABEL_TYPE,
	ICommonActionsDropdownComponentAction,
	ICommonActionsDropdownComponentActionData,
	ICommonActionsDropdownComponentActions,
	ICommonActionsDropdownComponentContextMenuData,
} from './common-actions-dropdown.interfaces';
import { getClosest } from '../../utilities/html';
import { COMMON_KEYBOARD_KEY } from '../../constants/keyboard.constant';
import { CommonFocusedBaseComponent } from '../../base-focused-component/common-focused-base.component';
import {
	COMMON_DIALOG_CONTEXT_MENU_SCREEN_INTERNAL_INTERFACE,
	CommonDialogContextMenuScreenComponent,
	ICommonDialogContextMenuScreenInternalInterface,
} from '../../blocks/dialog/common-dialog-context-menu-screen.component/common-dialog-context-menu-screen.component';
import { COMMON_DIALOG_DELETING_CLASS_MOD } from '../../blocks/dialog/common-dialog.component';
import { CommonDebugService, ICommonDebugPerformanceMeasure } from '@CaseOne/Common/debug/common-debug.service';

enum DIRECTION {
	UP = 1,
	DOWN = -1,
}
const DEFAULT_PARENT_ROW_CLASS = 'ui-table-row';
const DEFAULT_COMPONENT_CLASS = 'b-actions-dropdown';
const DISABLED_COMPONENT_CLASS = 'is-disabled';

@CommonDebugComponent({
	name: 'common-actions-dropdown-component',
	designLink: 'https://app.zeplin.io/project/591460ff7793c56928756817/screen/594a79e9e437a177c9118b18',
	description: '',
	props: {
		actions: {
			type: 'interface',
			description: 'List of actions in context menu',
			defaultValue: [{
				id: 'first_menu_item',
				name: 'First menu item',
				action: () => {
					alert('CLicked First');
				},
			}, {
				id: 'link_menu_item',
				name: 'This is link to another page',
				labelType: COMMON_ACTIONS_DROPDOWN_LABEL_TYPE.LINK,
				data: {
					href: 'https://google.com',
				},
			}, {
				id: 'DisabledOption',
				name: 'This is disabled option',
				isEnabled: () => false,
			}],
		},
		entity: {
			type: 'interface',
			description: 'Entity for actions',
			defaultValue: {
				Id: 'Id',
				Name: 'Name',
			},
		},
		cssClasses: {
			type: 'string',
			description: 'CSS classes for component',
			defaultValue: '',
		},
		parentRowClass: {
			type: 'string',
			description: 'CSS class for toggle parent row statu',
			defaultValue: '',
		},
		contextMenuOptions: {
			type: 'interface',
			description: 'Options for context menu',
			defaultValue: {},
		},
	},
	states: {
		isFocused: {
			type: 'boolean',
			description: 'Control is focused',
		},
		isHovered: {
			type: 'boolean',
			description: 'Cursor is over control',
		},
		isPressed: {
			type: 'boolean',
			description: 'User pressed control',
		},
	},
})
@Component({
	selector: 'common-actions-dropdown-component',
	template: '<ng-content></ng-content>',
	styleUrls: ['./common-actions-dropdown.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonActionsDropdownComponent<EntityType = any> extends CommonFocusedBaseComponent implements ICommonComponentWithContextMenu {
	@HostBinding('attr.tabindex') @Input() get formTabIndex(): number {
		return this.isDisabled ? null : this.tabIndex;
	}
	set formTabIndex(tabIndex) {
		this.tabIndex = tabIndex;
	}
	@HostBinding('attr.disabled') get isDisabled(): string {
		return (
			!this.actions ||
			!this.actions.length ||
			!this.actions.some(this.isActionEnabled)
		)
			? 'disabled'
			: null;
	}
	@HostBinding('class') get hostClass(): string {
		const classes = [DEFAULT_COMPONENT_CLASS];

		if (this.cssClasses) {
			classes.push(...this.cssClasses.split(' '));
		}
		if (this.isDisabled) {
			classes.push(DISABLED_COMPONENT_CLASS);
		}

		return classes.join(' ');
	}

	@Input() actions: ICommonActionsDropdownComponentActions; // A set of menu items along with options
	@Input() entity: EntityType; // Instance entity with which this menu operates
	@Input() cssClasses: string; // Stylistic classes to be added to the main element of the menu button
	@Input() parentRowClass: string = DEFAULT_PARENT_ROW_CLASS;  // css class for toggle parent row status, when context menu is opened
	@Input() contextMenuOptions: Partial<ICommonContextMenuOptions>; // Options for ContextMenu

	private contextMenu: CommonContextMenu<ICommonActionsDropdownComponentContextMenuData<EntityType>>;
	private currentAction: ICommonActionsDropdownComponentAction<EntityType> = null;
	private hoveredAction: ICommonActionsDropdownComponentAction<EntityType> = null;
	private tabIndex: number = 0;

	private openPerformanceMeasure: ICommonDebugPerformanceMeasure;

	constructor(
		protected elementRef: ElementRef,
		protected commonContextMenuFactory: CommonContextMenuFactory,
		private commonDebugService: CommonDebugService,
		injector: Injector,
	) {
		super(injector);
	}

	@HostListener('mousedown') onClick () {
		if (this.isDisabled) return;

		if (this.contextMenu) {
			this.closeContextMenu();
		} else {
			this.openContextMenu();
		}
	}

	@HostListener('document:keydown', ['$event']) handleKeyboardEvent (event: KeyboardEvent) {
		if (!this.contextMenu) {
			if (
				this.isFocused &&
				[
					COMMON_KEYBOARD_KEY.ENTER,
					COMMON_KEYBOARD_KEY.ARROW_DOWN,
					COMMON_KEYBOARD_KEY.SPACE,
				].includes(event.key)
			) {
				this.openContextMenu();
				this.stopEvent(event);
			}
		} else if (this.contextMenu.activeScreenName === 'default') {
			switch (event.key) {
				case COMMON_KEYBOARD_KEY.ARROW_RIGHT:
				case COMMON_KEYBOARD_KEY.ENTER:
					if (this.currentAction) {
						this.onActionClickHandler(this.currentAction, true);
					}
					this.stopEvent(event);
					break;
				case COMMON_KEYBOARD_KEY.ARROW_DOWN:
					this.changeCurrentActionByDirection(DIRECTION.DOWN);
					this.stopEvent(event);
					break;
				case COMMON_KEYBOARD_KEY.ARROW_UP:
					this.changeCurrentActionByDirection(DIRECTION.UP);
					this.stopEvent(event);
					break;
				case COMMON_KEYBOARD_KEY.ESCAPE:
					this.closeContextMenu();
					this.stopEvent(event);
					break;
				case COMMON_KEYBOARD_KEY.TAB:
					// suppress focus change
					this.stopEvent(event);
					break;
				default:
					// ignore event
			}
		}
	}

	ngOnDestroy() {
		if (this.openPerformanceMeasure) {
			this.openPerformanceMeasure.clear();
			this.openPerformanceMeasure = null;
		}

		super.ngOnDestroy();
	}

	// Open the context menu, will be used through Ref
	openContextMenu() {
		this.openPerformanceMeasure = this.commonDebugService.createPerformanceMeasure(
			'CommonActionsDropdownComponent',
			'beforeOpen',
			'afterOpen',
		);
		this.checkIsActionsValid();

		const parentRowToHighlight = getClosest(this.elementRef.nativeElement as HTMLElement, `.${ this.parentRowClass }`);

		this.contextMenu = this.commonContextMenuFactory.open<ICommonActionsDropdownComponentContextMenuData<EntityType>>({
			...{
				data: {
					actions: this.getPreparedActions(),
					dropdownComponentApi: {
						onActionClickHandler: (action) => this.onActionClickHandler(action),
						onActionMouseEnterHandler: (action) => this.onActionMouseEnterHandler(action),
						onActionMouseLeaveHandler: (action) => this.onActionMouseLeaveHandler(action),
						isActionEnabled: (action) => this.isActionEnabled(action),
						getCurrentAction: () => this.currentAction,
					},
				},
				targetEl: get(this.contextMenuOptions, 'targetEl', this.elementRef.nativeElement),
				attachment: get(this.contextMenuOptions, 'attachment', COMMON_CONTEXT_MENU_ATTACHMENTS.RIGHT),
				screens: {
					default: {
						component: CommonActionsDropdownOptionsListComponent,
					},
					...this.actions.reduce((screens, action) => {
						if (action.screen) {
							screens[action.screen.name] = action.screen;
						}

						return screens;
					}, {}),
				},
				events: {
					...get(this.contextMenuOptions, 'events', {}),
					[COMMON_CONTEXT_MENU_EVENT_NAME.AFTER_CLOSE]: (event) => {
						const contextMenuOptionsEvent = get(this.contextMenuOptions, `events['${COMMON_CONTEXT_MENU_EVENT_NAME.AFTER_CLOSE}']`);
						if (contextMenuOptionsEvent) contextMenuOptionsEvent(event);

						if (parentRowToHighlight) {
							parentRowToHighlight.classList.remove(this.parentRowClass + '--menu-opened');
						}
						this.contextMenu = null;
						this.hoveredAction = null;
						this.currentAction = null;
						this.runUpdate();
					},
					[COMMON_CONTEXT_MENU_EVENT_NAME.AFTER_OPEN]: (event) => {
						const contextMenuOptionsEvent = get(this.contextMenuOptions, `events['${COMMON_CONTEXT_MENU_EVENT_NAME.AFTER_OPEN}']`);
						if (contextMenuOptionsEvent) contextMenuOptionsEvent(event);

						if (parentRowToHighlight) {
							parentRowToHighlight.classList.add(this.parentRowClass + '--menu-opened');
						}
					},
				},
				injectedTokens: [{
					token: COMMON_DIALOG_CONTEXT_MENU_SCREEN_INTERNAL_INTERFACE,
					value: {
						entity: this.entity,
						getDialogOptions: () => this.getCurrentActionData(),
						backToDefault: () => this.backToDefault(),
						close: () => this.closeContextMenu(),
					} as ICommonDialogContextMenuScreenInternalInterface,
				}],
			},
			...(this.contextMenuOptions || {}),
		});

		if (this.openPerformanceMeasure) {
			this.openPerformanceMeasure.stop();
			this.openPerformanceMeasure = null;
		}
	}

	public getCurrentActionData(): ICommonActionsDropdownComponentActionData<EntityType> {
		return cloneDeep(this.currentAction && this.currentAction.data || null);
	}

	public onActionClickHandler(action: ICommonActionsDropdownComponentAction, isKeyboardEvent = false): void {
		if (action.action) {
			action.action(this.entity);
			this.contextMenu.close();
		} else if (action.labelType === COMMON_ACTIONS_DROPDOWN_LABEL_TYPE.LINK || action.labelType === COMMON_ACTIONS_DROPDOWN_LABEL_TYPE.LABEL) {
			if (isKeyboardEvent) {
				this.contextMenu.emitCustomEvent({
					type: COMMON_CONTEXT_MENU_EVENT_NAME.CUSTOM,
					payload: {
						type: COMMON_ACTIONS_DROPDOWN_CUSTOM_EVENT_TYPE.CURRENT_ACTION_SELECTED_BY_KEYBOARD,
					},
				});
			}
			this.contextMenu.close();
		} else if (action.screen) {
			this.contextMenu
				.changeScreenTo(action.screen.name, COMMON_CONTEXT_MENU_ANIMATION_DIRECTIONS.RIGHT_TO_LEFT)
				.catch(console.error);
		} else {
			console.warn('No action or screen in action\'s options');
			this.contextMenu.close();
		}
	}

	public onActionMouseEnterHandler(action: ICommonActionsDropdownComponentAction): void {
		this.hoveredAction = action;
		this.setCurrentAction(action);
	}

	public onActionMouseLeaveHandler(action: ICommonActionsDropdownComponentAction): void {
		if (action === this.hoveredAction) {
			this.hoveredAction = null;
			this.currentAction = null;
		}
	}

	// Closes the context menu, will be used through Ref
	public closeContextMenu() {
		if (this.contextMenu) {
			this.contextMenu.close();
		}
	}

	// Return to the default state
	public backToDefault() {
		this.contextMenu.changeScreenToDefault(COMMON_CONTEXT_MENU_ANIMATION_DIRECTIONS.LEFT_TO_RIGHT);
	}

	private changeCurrentActionByDirection(direction: DIRECTION): void {
		const actions = this.getPreparedActions();

		if (!actions.length || (this.currentAction && actions.length === 1)) {
			return;
		}

		this.hoveredAction = null;

		if (this.currentAction) {
			if (direction === DIRECTION.DOWN) {
				this.setCurrentAction(actions[actions.indexOf(this.currentAction) + 1] || actions[0]);
			} else {
				this.setCurrentAction(actions[actions.indexOf(this.currentAction) - 1] || actions[actions.length - 1]);
			}
		} else {
			const actionIndex = direction === DIRECTION.DOWN ? 0 : actions.length - 1;
			this.setCurrentAction(actions[actionIndex]);
		}
	}

	private setCurrentAction(action: ICommonActionsDropdownComponentAction): void {
		this.currentAction = action;

		this.contextMenu.emitCustomEvent({
			type: COMMON_CONTEXT_MENU_EVENT_NAME.CUSTOM,
			payload: {
				type: COMMON_ACTIONS_DROPDOWN_CUSTOM_EVENT_TYPE.CURRENT_ACTION_CHANGED,
			},
		});
	}

	private getEnabledActions(): ICommonActionsDropdownComponentActions {
		return this.actions.filter(this.isActionEnabled);
	}

	private getPreparedActions(): ICommonActionsDropdownComponentActions {
		return this.getEnabledActions()
			.map((action) => {
				const isDialog = action.contentType === COMMON_ACTIONS_DROPDOWN_CONTENT_TYPE.DIALOG;
				const isDeleteDialog = action.contentType === COMMON_ACTIONS_DROPDOWN_CONTENT_TYPE.DIALOG_DELETE;

				if ((isDialog || isDeleteDialog) && !action.screen) {
					if (isDeleteDialog) {
						extend(action.data, { cssClasses: COMMON_DIALOG_DELETING_CLASS_MOD });
					}

					action.screen = {
						name: 'commonActionsDropdownDialogComponent',
						component: CommonDialogContextMenuScreenComponent,
						data: action.data,
					};
				}

				return action;
			});
	}

	private isActionEnabled = (action: ICommonActionsDropdownComponentAction) => {
		return typeof action.isEnabled === 'undefined' || action.isEnabled(this.entity);
	}

	private stopEvent(event: Event): void {
		event.preventDefault();
	}

	private checkIsActionsValid(): void {
		if (this.actions.some((action) => !!action.action && !!action.screen)) {
			throw new Error('Action cannot to contain .action and .screen properties together');
		}
	}
}
