declare const COMPONENT_SPECS_MODE: boolean;
declare const WITH_COMPONENT_DEBUG: boolean;

import {
	commonDebugComponentService,
	ICommonDebugComponentServiceConfig,
} from './debug.service';

export const CONFIG_VALUE_PATH_SYMBOL = Symbol('__CONFIG');

export function CommonDebugComponent (config: ICommonDebugComponentServiceConfig) {
	commonDebugComponentService.saveConfig(config);

	return function DebugComponentE <TContractor> (constructor: TContractor): TContractor {
		if (WITH_COMPONENT_DEBUG && !COMPONENT_SPECS_MODE) {
			constructor[CONFIG_VALUE_PATH_SYMBOL] = config;
		}

		return constructor;
	};
}
