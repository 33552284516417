import { Pipe, PipeTransform } from '@angular/core';
import {
	COMMON_SUGGEST_WITH_TREE_DISPLAY_SEPARATOR
} from '@CaseOne/Common/controls/suggest-with-tree.component/common-suggest-with-tree-display-separator.constant';

@Pipe({
	name: 'commonHierarchyName',
})
export class CommonHierarchyNamePipe implements PipeTransform {
	transform (
		hierarchyNames: string[] = [],
		separator: string = COMMON_SUGGEST_WITH_TREE_DISPLAY_SEPARATOR,
	): string {
		return hierarchyNames.join(separator);
	}
}
