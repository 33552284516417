import { Injectable } from '@angular/core';
import { CommonResource } from '@CaseOne/Common/resource/resource.class';
import { CommonResourceAction } from '@CaseOne/Common/resource/decorators/resource-action.decorator';
import { COMMON_HTTP_METHOD_TYPE } from '@CaseOne/Common/interfaces/common-http-method-type.enum';
import { ICommonResourceMethod } from '@CaseOne/Common/resource/interfaces/resource-method.interface';
import { ICommonResponse } from '@CaseOne/Common/interfaces/response';
import { ICommonCheckInactiveSessionResponse } from '@CaseOne/Common/common-check-inactive/types';

@Injectable()
export class CommonCheckInactiveResourceService extends CommonResource {
	@CommonResourceAction({
		url: 'api/session/checkSession',
		method: COMMON_HTTP_METHOD_TYPE.GET,
	})
	checkSession: ICommonResourceMethod<void, ICommonResponse<ICommonCheckInactiveSessionResponse>>;

	@CommonResourceAction({
		url: 'api/session/ping',
		method: COMMON_HTTP_METHOD_TYPE.GET,
	})
	ping: ICommonResourceMethod<void, void>;
}
