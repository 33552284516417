import {
	ChangeDetectorRef,
	Input,
	NgZone,
	OnChanges,
	OnDestroy,
	OnInit,
	AfterViewInit,
	SimpleChanges,
	AfterContentInit,
	ViewRef,
	Directive,
	AfterViewChecked,
	Output,
	EventEmitter,
} from '@angular/core';
import { ICommonModel, ICommonModelWithTempId, TCallbackFunc } from '../interfaces/core';
import {
	forEach,
	map,
	remove,
} from 'lodash';
import { Observable, Subscription, Subject } from 'rxjs';
import { commonUtilitiesCoreService } from '../utilities/core.service';
import { CommonLocaleService } from '../locale/locale.service';
import { takeUntil, tap } from 'rxjs/operators';
import { CommonBaseEntityClass } from '../common-base-entity.class/common-base-entity.class';
import { MonoTypeOperatorFunction } from 'rxjs/internal/types';
import { CONFIG_VALUE_PATH_SYMBOL } from '../controls/control/debug/debug.decorator';
import { commonDebugComponentService } from '../controls/control/debug/debug.service';
import { COMMON_LOADER_DEFAULT_DELAY_TIMEOUT } from '@CaseOne/Common/loader/const/loader-default-delay-timeout';


declare const COMPONENT_SPECS_MODE: boolean;

// TODO: Need rename file to common-base-component.class because 'component' is name part
@Directive()
export class CommonBaseComponent extends CommonBaseEntityClass implements OnInit, AfterViewInit, AfterContentInit, AfterViewChecked, OnChanges, OnDestroy {
	/** @deprecated Use cssClasses from CommonBaseComponent */
	@Input() modifiers: string = '';
	@Input() cssClasses: string = ''; // Css classes for root element
	@Input() debugId: string; // DebugId use in CommonDebugComponent

	// Old Code integration
	@Output() getRef = new EventEmitter<CommonBaseComponent>(); // TODO: Need remove after full migration to new Angular

	protected commonLocaleService = this.injector.get(CommonLocaleService);
	protected changeDetector = this.injector.get(ChangeDetectorRef);
	protected zone = this.injector.get(NgZone);

	/** @deprecated */
	protected modifierSeparator: string = '--';
	/** @deprecated */
	protected classesSeparator: string = ' ';
	protected needUnbind: TCallbackFunc[] = [];

	private $destroy: Subject<null>;

	ngOnInit () {
		if (this.debugId) {
			const componentConfigValue = this.constructor[CONFIG_VALUE_PATH_SYMBOL];

			if (componentConfigValue) {
				commonDebugComponentService.registerComponent(componentConfigValue, this.debugId, this);
			}
		}

		this.getRef.emit(this);
	}

	ngAfterViewInit () {
		// Not Empty
	}

	ngAfterViewChecked () {
		// Not Empty
	}

	ngAfterContentInit () {
		// Not Empty
	}

	ngOnChanges (changes: SimpleChanges) {
		// Empty
	}

	ngOnDestroy () {
		forEach(this.needUnbind, (callback) => {
			callback();
		});

		if (this.$destroy) {
			this.$destroy.next();
			this.$destroy.complete();
		}
	}

	defaultTrackBy(index: number, item: any): any {
		return index;
	}

	defaultTrackById(index: number, item: ICommonModel): any {
		return item.Id;
	}

	defaultTrackByIdOrTempId(index: number, item: ICommonModelWithTempId): any {
		return item.Id || item.TempId;
	}

	runUpdate () {
		if (!(this.changeDetector as ViewRef).destroyed) {  // FIX "Attempt to use a destroyed view: detectChanges"
			this.changeDetector.detectChanges();
		}
	}

	/** @deprecated */
	protected getBaseClasses (cssBaseClass: string): string[] {
		const classes = [cssBaseClass];

		if (this.modifiers.length) {
			const modifiers = this.modifiers.split(this.classesSeparator);
			const modifierClasses = map(modifiers, (modifier) => {
				return cssBaseClass + this.modifierSeparator + modifier;
			});

			return classes.concat(modifierClasses);

		} else {
			return classes;
		}
	}

	/** @deprecated */
	protected getBaseClassesAsString (cssBaseClass: string): string {
		return this.getBaseClasses(cssBaseClass).join(this.classesSeparator);
	}

	protected setTimeout = (func, time?: number): NodeJS.Timeout => {
		const timer = setTimeout(func, time);

		this.createDestroySubject();

		this.$destroy
			.subscribe(() => {
				clearTimeout(timer);
			});

		return timer;
	}

	protected setInterval = (func, time?: number): NodeJS.Timeout => {
		const timer = setInterval(func, time);

		this.createDestroySubject();

		this.$destroy
			.subscribe(() => {
				clearInterval(timer);
			});

		return timer;
	}

	// Timeout with additional functional
	// The third argument it is possible to transfer function which of all is performed in case of completion of the timer
	// Returns canceling function
	protected timeout = (
		func: TCallbackFunc = () => void (0),
		time: number = 0,
		finallyFunc: TCallbackFunc = () => void (0),
	): TCallbackFunc => {
		const timer = this.timeoutWithZone(() => {
			func();
			finallyFunc();
		}, time);

		return () => {
			clearTimeout(timer);
			finallyFunc();
		};
	}

	protected timeoutWithZone = (
		callback: TCallbackFunc = () => void (0),
		time: number = 0,
	): any => {
		return this.setTimeout(() => {
			this.zone.run(callback);
		}, time);
	}

	protected intervalWithZone(
		callback: TCallbackFunc = () => void (0),
		time: number = 0,
	): number {
		return window.setInterval(() => {
			this.zone.run(callback);
		}, time);
	}

	protected asyncTimeout (...args): Promise<void> {
		return commonUtilitiesCoreService.asyncTimeout(...args);
	}

	protected hasValue (value: any): boolean {
		return commonUtilitiesCoreService.hasValue(value);
	}

	protected willUnbind (callback: () => void): (withUnbind?: boolean) => void {
		this.needUnbind.push(callback);

		return (withUnbind: boolean = false): void => {
			remove(this.needUnbind, (f) => f === callback);

			if (withUnbind) {
				callback();
			}
		};
	}

	protected markForCheck () {
		this.changeDetector.markForCheck();
	}

	protected runOutsideAngular<T> (func: (...args: any[]) => T): T {
		return this.zone.runOutsideAngular(func);
	}

	protected subscribe<T> (observable: Observable<T> | Subject<T>, generatorOrNext?: (value: T) => void, catchError?: (value: any) => void): Subscription {
		return observable
			.pipe(this.takeUntilDestroy())
			.subscribe(generatorOrNext, catchError);
	}

	protected subscribeWithRun<T> (observable: Observable<T> | Subject<T>, generatorOrNext?: any): Subscription {
		return observable.pipe(
			this.takeUntilDestroy(),
			tap(generatorOrNext),
			tap(() => this.runUpdate()),
		).subscribe();
	}

	protected addEventListener(
		target: EventTarget,
		event: string,
		callback: EventListener,
		options?: boolean | AddEventListenerOptions,
	): (withUnbind?: boolean) => void {
		target.addEventListener(event, callback, options);

		return this.willUnbind(() => {
			target.removeEventListener(event, callback, options);
		});
	}

	// For tests
	protected preventDefault(event) {
		if (event && event.preventDefault) {
			event.preventDefault();
		}
	}

	protected warn (text: string) {
		if (!COMPONENT_SPECS_MODE && text) {
			console.warn(text);
		}
	}

	protected takeUntilDestroy<T>(): MonoTypeOperatorFunction<T> {
		this.createDestroySubject();

		return takeUntil(this.$destroy);
	}

	private createDestroySubject() {
		if (!this.$destroy) {
			this.$destroy = new Subject<null>();
		}
	}
}
