import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';

export class CommonLocaleHttpLoader implements TranslateLoader {
	constructor(private http: HttpClient) {}

	getTranslation(lang: string): Observable<any> {
		const localeUrl = window.LocaleData.localeUrl;

		return this.http
			.get(localeUrl);
	}
}
