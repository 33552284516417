import {
	ChangeDetectionStrategy,
	Component,
	Inject,
	Injector,
	QueryList,
	ViewChildren,
	ViewEncapsulation,
} from '@angular/core';

import { COMMON_CONTEXT_MENU_EVENT_NAME } from '../../../context-menu.service/common-context-menu.interfaces';
import {
	COMMON_CONTEXT_MENU_DATA,
	COMMON_CONTEXT_MENU_ENTITY,
	CommonContextMenu,
} from '../../../context-menu.service/common-context-menu.service';
import { CommonContextMenuBaseScreenComponent } from '../../../context-menu.service/components/common-context-menu-base-screen.component';
import {
	COMMON_ACTIONS_DROPDOWN_CUSTOM_EVENT_TYPE,
	ICommonActionsDropdownComponentContextMenuData,
	ICommonActionsDropdownComponentAction,
	ICommonActionsDropdownComponentActions,
} from '../../common-actions-dropdown.interfaces';
import { CommonActionsDropdownOptionComponent } from '../common-actions-dropdown-option/common-actions-dropdown-option.component';

@Component({
	selector: 'common-actions-dropdown-options-list',
	templateUrl: './common-actions-dropdown-options-list.component.pug',
	styleUrls: ['./common-actions-dropdown-options-list.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class CommonActionsDropdownOptionsListComponent<DataType extends ICommonActionsDropdownComponentContextMenuData<EndingType>> extends CommonContextMenuBaseScreenComponent {
	options: ICommonActionsDropdownComponentActions = [];

	@ViewChildren(CommonActionsDropdownOptionComponent)
	private optionComponents: QueryList<CommonActionsDropdownOptionComponent<ICommonActionsDropdownComponentAction>>;

	constructor(
		@Inject(COMMON_CONTEXT_MENU_DATA) public data: DataType,
		@Inject(COMMON_CONTEXT_MENU_ENTITY) public commonContextMenu: CommonContextMenu<DataType>,
		injector: Injector,
	) {
		super(injector);
		this.options = this.data.actions;
		this.subscribe(
			this.commonContextMenu.onEvent(COMMON_CONTEXT_MENU_EVENT_NAME.CUSTOM),
			(payload) => {
				if (payload.payload.type === COMMON_ACTIONS_DROPDOWN_CUSTOM_EVENT_TYPE.CURRENT_ACTION_CHANGED) {
					this.changeDetector.detectChanges();
				}

				if (payload.payload.type === COMMON_ACTIONS_DROPDOWN_CUSTOM_EVENT_TYPE.CURRENT_ACTION_SELECTED_BY_KEYBOARD) {
					this.emulateClickForKeyboardSelection();
				}
			},
		);
	}

	isOptionEnabled(option: ICommonActionsDropdownComponentAction): boolean {
		return this.data.dropdownComponentApi.isActionEnabled(option);
	}

	onOptionClickHandler(option: ICommonActionsDropdownComponentAction) {
		this.data.dropdownComponentApi.onActionClickHandler(option);
	}

	onOptionMouseEnterHandler(option: ICommonActionsDropdownComponentAction) {
		this.data.dropdownComponentApi.onActionMouseEnterHandler(option);
	}

	onOptionMouseLeaveHandler(option: ICommonActionsDropdownComponentAction) {
		this.data.dropdownComponentApi.onActionMouseLeaveHandler(option);
	}

	isActiveOption(option: ICommonActionsDropdownComponentAction) {
		return this.data.dropdownComponentApi.getCurrentAction() === option;
	}

	private emulateClickForKeyboardSelection() {
		const currentOption = this.optionComponents.find((optionElement) => optionElement.isActive);
		let targetElementSelector;
		let targetElement;

		if (currentOption.option.labelType === currentOption.labelTypes.LINK) {
			targetElementSelector = 'a';
		} else if (currentOption.option.labelType === currentOption.labelTypes.LABEL) {
			targetElementSelector = 'label';
		}

		if (targetElementSelector) {
			targetElement = currentOption.elementRef.nativeElement.querySelector(targetElementSelector);
		}

		if (targetElement) {
			targetElement.click();
		}
	}
}
