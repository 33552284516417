import { NgModuleRef, ApplicationRef } from '@angular/core';
import { ICommonAppConfig } from './app.config.token';
import { CommonPageTitleService } from '../page-title/page-title.service';

// FIX for the HYBRID
// https://stackoverflow.com/questions/44802253/cannot-start-ui-router-hybrid-app-with-systemjs
import { servicesPlugin } from '@uirouter/angular';
import { CommonDebugService } from '@CaseOne/Common/debug/common-debug.service';
servicesPlugin(null);

function getTimerText (layoutTranslations, time: number): string {
	return `${layoutTranslations.Layout_Reconnect_StartMessage} ${time} ${layoutTranslations.Layout_Reconnect_Seconds}.`;
}

export function initApp<M> (
	platformBrowserDynamicPromise: Promise<NgModuleRef<M>>,
	config: ICommonAppConfig,
	callback?: (moduleRef: NgModuleRef<M>) => Promise<NgModuleRef<M>>,
): Promise<any> {
	const layoutTranslations = window.LocaleData.layoutTranslations;
	const loader = window.initialLoader;

	return platformBrowserDynamicPromise
		.then((moduleRef) => {
			if (callback) {
				return callback(moduleRef);
			} else {
				const commonPageTitleService = moduleRef.injector.get(CommonPageTitleService);

				commonPageTitleService.startWatchingState();

				return moduleRef;
			}
		})
		.then((moduleRef) => {
			const commonDebugService = moduleRef.injector.get(CommonDebugService);

			// enable debug tools
			if (commonDebugService.isDebugToolsEnabled) {
				const applicationRef = moduleRef.injector.get(ApplicationRef);
				const appComponent = applicationRef.components[0];

				// hybrid Angular+AngularJS apps do not have a root component
				if (appComponent) {
					commonDebugService.enableDebugTools(appComponent);
				}
			}

			// disable main app loader
			if (loader) {
				loader.disable();
			}

			return moduleRef;
		})
		.catch((error) => {
			document.title = layoutTranslations.Layout_RunError;

			console.error(error);

			if (!loader) {
				return;
			}

			const second = 1000;
			const countdownTime = 60; // seconds
			const end = new Date().getTime() + countdownTime * second;

			loader.setLabel(layoutTranslations.Layout_Error);
			loader.error({
				text: layoutTranslations.Layout_Error_Message,
				description: error.data && error.data.Error ? `${layoutTranslations.Layout_ServerResponse} ${error.data.Error}` : '',
				timer: getTimerText(layoutTranslations, countdownTime),
			});

			// Without zonejs
			setTimeout(function countdown () {
				const now = new Date().getTime();

				if (end < now) {
					location.reload();
				} else {
					const countDownSecond = Math.round((end - now) / second);

					loader.error({
						timer: getTimerText(layoutTranslations, countDownSecond),
					});

					// Without zonejs
					setTimeout(countdown, second);
				}
			}, second);
		});
}

export function commonInitApp<M> (platformBrowserDynamicPromise: Promise<NgModuleRef<M>>, config: ICommonAppConfig): Promise<any> {
	return initApp(platformBrowserDynamicPromise, config);
}
