// Old version CommonDialogWidget src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/widgets/common_dialog_widget_directive.js
// New version CommonDialogComponent src/Common/blocks/dialog/common-dialog.component.ts

import {
	ChangeDetectionStrategy,
	Component,
	HostListener,
	Input,
	SimpleChanges,
	ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { CommonBaseComponent } from '../../base_component/base.component';
import { isObservable, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { isPromise } from 'rxjs/internal-compatibility';

export const COMMON_DIALOG_DELETING_CLASS_MOD = 'b-dialog--deleting';

export interface ICommonDialogOptions<EntityType = any> {
	cssClasses?: string,
	header?: string,
	text?: string,
	textFunc?: (entity: EntityType) => string,

	acceptFunc?: (entity: EntityType, dialog: any) => void,
	acceptText?: string,

	declineFunc?: (entity: EntityType, dialog: any) => void,
	declineText?: string,
	isShowDeclineButton?: boolean,

	closeText?: string,
}

@Component({
	selector: 'common-dialog',
	templateUrl: './common-dialog.component.pug',
	styleUrls: ['./common-dialog.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class CommonDialogComponent<ItemType = any> extends CommonBaseComponent {
	@Input() cssClasses: string;  // css classes for dialog
	@Input() item: ItemType; // Item for dialog
	@Input() header: string; // Text for header in dialog
	@Input() text: string; // Main text in dialog
	@Input() textFunc: (item: ItemType) => string; // Generate main text from item
	@Input() isLoading: boolean = false;  // Show full overlay dialog loader
	@Input() isContentLoading: boolean = false;  // Show content loader
	@Input() disableScroll: boolean = true;  // disable scroll inside dialog

	@Input() acceptFunc: (item: ItemType, dialog: CommonDialogComponent) => Promise<any> | Observable<any> | void; // Callback for accept button
	@Input() acceptText: string; // Text for accept button
	@Input() isDisabledAcceptBtn: boolean = false;
	@Input() isShowAcceptBtn: boolean = true; // Display accept button?

	@Input() declineFunc: (item: ItemType, dialog: CommonDialogComponent) => Promise<any> | Observable<any> | void; // Callback for decline button
	@Input() declineText: string; // Text for decline button
	@Input() isShowDeclineButton: boolean = true; // Display decline button?

	@Input() backFunc: (item: ItemType, dialog: CommonDialogComponent) => Promise<any> | Observable<any> | void; // Callback for back button

	@Input() closeFunc: (item: ItemType, dialog: CommonDialogComponent) => Promise<any> | Observable<any> | void; // Callback for close button
	@Input() closeText: string; // Text for close button

	@Input() readonly hideButtons: boolean; // to hide both accept and decline btns

	private isWaitingResultEnabled = false;  // Fix for CASEM-59208 (mass click protection)

	@HostListener('document:keydown.escape') async onKeydownEscape() {
		if (this.backFunc) {
			this.onBackClicked();
		} else if (this.closeFunc) {
			this.onCloseClicked();
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		super.ngOnChanges(changes);

		if (this.text && this.textFunc) {
			throw new Error('Cannot to @Input text and textFunc together');
		}
	}

	onCloseClicked(): void {
		this.close();
	}

	close(): void {
		this.closeFunc(this.item, this);
	}

	getSanitizedText(): SafeHtml {
		const domSanitizer = this.injector.get(DomSanitizer);

		const text: string = this.textFunc ? this.textFunc(this.item) : this.text;

		return domSanitizer.bypassSecurityTrustHtml(text);
	}

	onAcceptClicked(): void {
		if (this.isWaitingResultEnabled || this.isDisabledAcceptBtn) {
			return;
		}

		this.isWaitingResultEnabled = true;
		this.waitResult(this.acceptFunc(this.item, this));
	}

	onDeclineClicked(): void {
		if (this.isWaitingResultEnabled) {
			return;
		}

		this.isWaitingResultEnabled = true;
		this.waitResult(this.declineFunc(this.item, this));
	}

	onBackClicked(): void {
		this.backFunc(this.item, this);
	}

	private waitResult(res: Promise<any> | Observable<any> | void) {
		if (isPromise(res)) {
			res
				.finally(() => {
					this.isWaitingResultEnabled = false;
				});
		} else if (isObservable(res)) {
			this.subscribeWithRun(
				res.pipe(
					finalize(() => {
						this.isWaitingResultEnabled = false;
					}),
				),
			);
		} else {
			this.isWaitingResultEnabled = false;
		}
	}
}
