import { Point } from './point';

export class Rect {
	public lt: Point;
	public rb: Point;

	constructor(element: HTMLElement);
	constructor(ltPoint: Point, rbPoint: Point);
	constructor(ltX: number, ltY: number, rbX: number, rbY: number);
	constructor(...args) {
		this.lt = new Point();
		this.rb = new Point();

		if (args.length === 1) {
			const elem: HTMLElement = args[0];
			const rawRect = elem.getBoundingClientRect();

			this.lt = new Point(rawRect.left, rawRect.top);
			this.rb = new Point(rawRect.right, rawRect.bottom);
		}

		if (args.length === 2) {
			this.lt = args[0];
			this.rb = args[1];
		}

		if (args.length === 4) {
			this.lt = new Point(args[0], args[1]);
			this.rb = new Point(args[2], args[3]);
		}
	}

	get height(): number {
		return this.rb.y - this.lt.y;
	}

	get width(): number {
		return this.rb.x - this.lt.x;
	}

	get center(): Point {
		return new Point((this.rb.x + this.lt.x) / 2, (this.rb.y + this.lt.y) / 2);
	}

	// Leaves only the intersection of figures
	mergeRects(...args: Rect[]): this {
		args
			.filter((rect)  => !!rect)
			.forEach((rect) => {
				this.lt.max(rect.lt);
				this.rb.min(rect.rb);
			});

		return this;
	}

	getCenter(): Point {
		return this.center;
	}

	clone(): Rect {
		return new Rect(this.lt.clone(), this.rb.clone());
	}

	isHasCollision(rect: Rect): boolean {
		const noOverlap = this.lt.x > rect.rb.x ||
			rect.lt.x > this.rb.x ||
			this.lt.y > rect.rb.y ||
			rect.lt.y > this.rb.y;

		return !noOverlap;
	}
}
