import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import {
	ComponentRef,
	Injectable,
	Injector,
} from '@angular/core';
import {
	ComponentPortal,
	PortalInjector,
} from '@angular/cdk/portal';
import {
	defer,
	forkJoin,
	Observable,
	Subject,
	BehaviorSubject,
} from 'rxjs';
import { TransitionService } from '@uirouter/core';

import { CommonPopupOptions } from '../popup-options';
import { CommonPopup } from '../popup';
import { CommonPopupContainerComponent } from '../components/popup-container/popup-container.component';
import { COMMON_POPUP_DATA } from '../constants/popup-data.constant';
import { CommonPopupOverlayBackgroundComponent } from '../components/popup-overlay-background/popup-overlay-background.component';
import { CommonPopupLoaderComponent } from '../components/popup-loader/popup-loader.component';
import { COMMON_POPUP_ON_LOAD_DATA } from '../constants/popup-on-load-data.constant';
import { CommonNotificationService } from '../../notification/notification.service';
import { CommonLocaleService } from '../../locale/locale.service';
import { COMMON_POPUP_ACTION_TYPE } from '../constants/popup-action-type.constant';


declare const COMPONENT_SPECS_MODE: boolean;

@Injectable()
export class CommonPopupService {
	// TODO: [PopupFactory] Remove after replace of old PopupFactory usage
	public oldPopupsIntegrationMode = false;
	public popups$ = new BehaviorSubject<CommonPopup[]>([]);
	public openPopup$ = new Subject<CommonPopup>();
	public closePopup$ = new Subject<CommonPopup>();
	public openFirstPopup$ = new Subject<CommonPopupOptions>();
	public closeLastPopup$ = new Subject<CommonPopup>();
	// TODO END

	private popups: CommonPopup[] = [];
	private backgroundOverlayRef: OverlayRef;
	private backgroundComponentRef: ComponentRef<CommonPopupOverlayBackgroundComponent>;
	private loaderOverlayRef: OverlayRef;
	private loaderComponentRef: ComponentRef<any> | undefined;
	private isLoading: boolean = false;
	private commonNotificationService = this.injector.get(CommonNotificationService);
	private commonLocaleService = this.injector.get(CommonLocaleService);

	constructor(
		protected overlay: Overlay,
		protected injector: Injector,
		protected transitionService: TransitionService,
	) {
		this.transitionService.onBefore({}, (transition) => {
			if (transition.from().name !== transition.to().name) {
				this.closeAll();
			}
		});

		// TODO: [PopupFactory] Remove after replace of old PopupFactory usage
		// Circular dependency
		setTimeout(() => {
			// Force init old service
			try {
				this.injector.get('$injector').get('PopupQueueService');
			} catch (error) {
				if (!COMPONENT_SPECS_MODE) {
					console.warn(error);
				}
			}
		});
		// TODO END
	}

	open(
		options: CommonPopupOptions,
	): CommonPopup {
		if (options.contextMenuItems) {
			console.warn('CommonPopupOptions#contextMenuItems is deprecated, use `this.popup.containerInstance.setContextMenuItems` function in popup component');
		}

		this.createBackgroundOverlay();

		const previousPopup = this.popups[this.popups.length - 1];
		const popupRef = this.createPopup(options);

		/**
		 * If new popup have onLoadData, then first of all we need to close or hide previous open popup if it exists.
		 * Then need to show loader, and after load of data - hide loader and show new popup.
		 *
		 * If there is no onLoadData - simply hide or close previous popup ( also with check ) and show new.
		 */
		this.previousPopupClosePromise(previousPopup, options)
			.then(() => {
				if (options.onLoadData) {
					this.showLoader();
					return options.onLoadData;
				}
			})
			.finally(() => this.hideLoader())
			.then(() => {
				popupRef.render();

				if (popupRef.withControlOpenState) {
					popupRef.show();
				}
			})
			.catch((error) => {
				if (error && error.status === 404 && error.error.Error) {
					this.commonNotificationService.show(this.commonLocaleService.instant('common.noty.error.title'), error.error.Error, 'error');
				}
				console.error(error);

				if (popupRef.withControlOpenState) {
					return popupRef.close();
				}
			});

		return popupRef;
	}

	openOverlap (options: CommonPopupOptions): CommonPopup {
		if (options.contextMenuItems) {
			console.warn('CommonPopupOptions#contextMenuItems is deprecated, use `this.popup.containerInstance.setContextMenuItems` function in popup component');
		}
		this.createBackgroundOverlay();
		const popupRef = this.createPopup(options);
		popupRef.render();
		popupRef.show();
		return popupRef;
	}

	closeAll(): Promise<any> {
		return this.closePopups(this.popups);
	}

	isOpen(): boolean {
		return !!this.popups.length;
	}

	private createPopup(
		options: CommonPopupOptions,
	): CommonPopup {
		options = this.applyConfigDefaults(options);

		const overlayRef: OverlayRef = this.createOverlay();
		const popupContainer = this.attachPopupContainer(overlayRef, options);
		const popupRef = this.attachPopupContent(
			popupContainer,
			overlayRef,
			options,
		);

		this.popups.push(popupRef);

		// TODO: [PopupFactory] Remove after replace of old PopupFactory usage
		if (this.oldPopupsIntegrationMode) {
			this.popups$.next(this.popups);

			if (this.popups.length === 1) {
				this.openFirstPopup$.next(options);
			}

			this.openPopup$.next(popupRef);
		}
		// TODO END

		const popupRefBeforeClose$ = popupRef
			.onEvent(COMMON_POPUP_ACTION_TYPE.BEFORE_CLOSE)
			.subscribe(() => {
				this.beforeClosePopup(popupRef);
				popupRefBeforeClose$.unsubscribe();
			});

		const popupRefAfterClose$ = popupRef
			.onEvent(COMMON_POPUP_ACTION_TYPE.AFTER_CLOSE)
			.subscribe(() => {
				this.afterClosePopup(popupRef);
				popupRefAfterClose$.unsubscribe();
			});

		return popupRef;
	}

	private createOverlay(): OverlayRef {
		const overlayConfig: OverlayConfig = this.getOverlayConfig();

		return this.overlay.create(overlayConfig);
	}

	protected getOverlayConfig(): OverlayConfig {
		return new OverlayConfig({
			hasBackdrop: false,
		});
	}

	protected attachPopupContainer(
		overlay: OverlayRef,
		options: CommonPopupOptions,
	): CommonPopupContainerComponent {
		const injector = new PortalInjector(this.injector, new WeakMap([
			[CommonPopupOptions, options],
		]));
		const containerPortal = new ComponentPortal(CommonPopupContainerComponent, null, injector);
		const containerRef = overlay.attach(containerPortal);
		containerRef.instance.isOverlapped = options.isOverlapped;
		containerRef.instance.setDestroyFunc(() => { containerRef.destroy(); });

		return containerRef.instance;
	}

	private attachPopupContent(
		popupContainer: CommonPopupContainerComponent,
		overlayRef: OverlayRef,
		config: CommonPopupOptions,
	): CommonPopup {
		const popupRef = new CommonPopup(overlayRef, popupContainer);
		popupRef.withControlOpenState = config.withControlOpenState;
		popupRef.injector = this.createInjector(config, popupRef, popupContainer);

		popupContainer.popupRef = popupRef;

		return popupRef;
	}

	private createInjector(
		options: CommonPopupOptions,
		popupRef: CommonPopup,
		popupContainer: CommonPopupContainerComponent,
	): PortalInjector {
		const injectionTokens = new WeakMap<any, any>([
			[CommonPopupContainerComponent, popupContainer],
			[COMMON_POPUP_DATA, options.data],
			[COMMON_POPUP_ON_LOAD_DATA, options.onLoadData],
			[CommonPopup, popupRef],
		]);

		return new PortalInjector(this.injector, injectionTokens);
	}

	private beforeClosePopup(
		popupRef: CommonPopup,
	) {
		const index = this.popups.indexOf(popupRef);
		const originalPopups = [...this.popups];
		const deletedPopup = originalPopups[index];

		originalPopups.splice(index, 1);

		// TODO: [PopupFactory] Remove after replace of old PopupFactory usage
		if (this.oldPopupsIntegrationMode) {
			if (originalPopups.length === 0) {
				this.closeLastPopup$.next(deletedPopup);
			}
		}
		// TODO END
	}

	private afterClosePopup(
		popupRef: CommonPopup,
	) {
		const index = this.popups.indexOf(popupRef);
		const originalPopups = [...this.popups];

		if (index > -1) {
			const deletedPopup = this.popups[index];

			this.popups.splice(index, 1);

			// TODO: [PopupFactory] Remove after replace of old PopupFactory usage
			if (this.oldPopupsIntegrationMode) {
				this.popups$.next(this.popups);
				this.closePopup$.next(popupRef);
			}
			// TODO END

			deletedPopup.overlayRef.dispose();

			/**
			 * Check if closed popup last and there is some popups behind it. If so - show previous popup.
			 */
			if (
				this.popups.length &&
				index + 1 === originalPopups.length
			) {
				const nextPopup = this.popups[this.popups.length - 1];

				if (nextPopup.withControlOpenState) {
					nextPopup.show();
				}
			}

			// TODO: [PopupFactory] Remove after replace of old PopupFactory usage
			if (!this.oldPopupsIntegrationMode) {
			// TODO END

				this.removeBackground();
			}
		}
	}

	protected removeBackground () {
		/**
		 * If closed popup is last - destroy background overlay
		 */
		if (!this.popups.length) {
			const backgroundClose$ = this.backgroundComponentRef.instance.close()
				.subscribe(() => {
					this.backgroundOverlayRef.dispose();
					backgroundClose$.unsubscribe();
				});
		}
	}

	private closePopups(
		popups: CommonPopup[],
	): Promise<any> {
		return forkJoin(
			popups
				.slice(0)
				.reverse()
				.map(
					(popup) => popup.close(),
				),
		)
			.toPromise();
	}

	protected applyConfigDefaults(
		config: CommonPopupOptions,
	): CommonPopupOptions {
		const defaultOptions = new CommonPopupOptions();

		return { ...defaultOptions, ...config };
	}

	protected createBackgroundOverlay() {
		if (this.popups.length) {
			return;
		}

		// TODO: [PopupFactory] Remove after replace of old PopupFactory usage
		if (!this.oldPopupsIntegrationMode) {
		// TODO END

			const backgroundComponentPortal = new ComponentPortal(CommonPopupOverlayBackgroundComponent);
			this.backgroundOverlayRef = this.overlay.create(new OverlayConfig({ hasBackdrop: false }));
			this.backgroundComponentRef = this.backgroundOverlayRef.attach(backgroundComponentPortal);
		}
	}

	private showLoader(): void {
		if (this.isLoading) {
			return;
		}

		const loaderComponentPortal = new ComponentPortal(CommonPopupLoaderComponent);

		this.isLoading = true;
		this.loaderOverlayRef = this.overlay.create(new OverlayConfig({ hasBackdrop: false }));
		this.loaderComponentRef = this.loaderOverlayRef.attach(loaderComponentPortal);
		this.loaderComponentRef.instance.runUpdate();
	}

	private hideLoader(): void {
		if (!this.isLoading) {
			return;
		}

		this.isLoading = false;
		this.loaderComponentRef.destroy();
		this.loaderOverlayRef.dispose();
		this.loaderComponentRef = undefined;
	}

	private hideOrClosePopup(
		popup: CommonPopup,
		options: CommonPopupOptions,
	): Observable<any> {
		return defer(() => {
			return options.closePrev || popup.isCloseOnNext() ? popup.close() : popup.hide();
		});
	}

	private previousPopupClosePromise(
		popup: CommonPopup,
		options: CommonPopupOptions,
	): Promise<any> {
		return popup ? this.hideOrClosePopup(popup, options).toPromise() : Promise.resolve();
	}
}
