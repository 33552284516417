import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonMinValidatorDirective} from './min.directive';
import { CommonModule } from '@angular/common';

@NgModule({
	imports: [
		FormsModule,
		CommonModule,
	],
	exports: [
		CommonMinValidatorDirective,
	],
	declarations: [
		CommonMinValidatorDirective,
	],
})
export class CommonMinValidatorModule {}
