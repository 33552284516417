import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonEmailValidatorDirective } from './email.directive';
import { CommonModule } from '@angular/common';
import { CommonEmailValidator } from '@CaseOne/Common/control_validators/email/email.validator';

@NgModule({
	imports: [
		FormsModule,
		CommonModule,
	],
	providers: [
		CommonEmailValidator,
	],
	exports: [
		CommonEmailValidatorDirective,
	],
	declarations: [
		CommonEmailValidatorDirective,
	],
})
export class CommonEmailValidatorModule {}
