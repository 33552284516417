import { COMMON_MAX_PAGE_SIZE } from '@CaseOne/Common/constants/common-max-page-size.constant';
import {
	ICommonListResourceServiceQueryData,
} from '@CaseOne/Common/base-list-resource.service/common-base-list-resource.service';
import { ICommonResponse } from '@CaseOne/Common/interfaces/response';
import { EMPTY, Observable } from 'rxjs';
import { expand, reduce } from 'rxjs/operators';
import { TCommonBigQueryRequestFn } from '@CaseOne/Common/big-query/common-big-query.types';
import { IPromiseWithCancel } from '@CaseOne/Common/utilities/core.service';


export async function bigQuery<ItemType> (
	requestFn: TCommonBigQueryRequestFn<ItemType>,
	requestParams: ICommonListResourceServiceQueryData = {},
	pageSize: number = COMMON_MAX_PAGE_SIZE,
	isOldRequest: boolean = false, // TODO: For old requests with $promise. Remove this parameter after all requests will be updated.
): Promise<ICommonResponse<ItemType[]>> {
	requestParams.Page = requestParams.Page ? requestParams.Page : 1;
	requestParams.PageSize = pageSize;

	const response = isOldRequest
		? await requestFn(requestParams).$promise
		: await requestFn(requestParams);

	if (response.NextPageExists) {
		requestParams.Page++;
		const intermediateResult = await bigQuery(requestFn, requestParams, pageSize, isOldRequest);
		response.Result = response.Result.concat(intermediateResult.Result);
	}

	response.NextPageExists = false;

	return response;
}

export async function bigQuerySrcFunc<ItemType> (
	requestFn: (query: ICommonListResourceServiceQueryData) => IPromiseWithCancel<ItemType[]>,
	requestParams: ICommonListResourceServiceQueryData = {},
	nextPageExistsFn: (items: ItemType[], queryParams: ICommonListResourceServiceQueryData) => boolean,
	pageSize: number = COMMON_MAX_PAGE_SIZE,
): Promise<ItemType[]> {
	requestParams.Page = requestParams.Page ? requestParams.Page : 1;
	requestParams.PageSize = pageSize;

	let response = await requestFn(requestParams);

	if (nextPageExistsFn(response, requestParams)) {
		requestParams.Page++;
		const intermediateResult = await bigQuerySrcFunc(requestFn, requestParams, nextPageExistsFn, pageSize);
		response = response.concat(intermediateResult);
	}

	return response;
}

export function bigQueryObservable<ItemType> (
	fn: (...args) => Observable<ICommonResponse<ItemType[]>>,
	requestParams: ICommonListResourceServiceQueryData = {},
	pageSize: number = COMMON_MAX_PAGE_SIZE,
): Observable<ICommonResponse<ItemType[]>> {
	requestParams.Page = requestParams.Page ? requestParams.Page : 1;
	requestParams.PageSize = pageSize;

	return fn(requestParams).pipe(
		expand((response) => {
			if (response.NextPageExists) {
				requestParams.Page++;
				return fn(requestParams);
			}

			return EMPTY;
		}),
		reduce((acc, val) => {
			acc.Result = acc.Result.concat(val.Result);

			return acc;
		}, {
			Result: [],
			NextPageExists: false,
		}),
	) as Observable<ICommonResponse>;
}

