import { FormControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import {CommonRequireValidator, ICommonRequireValidatorContext} from './required.validator';
import {CommonBaseEntityClass} from '../../common-base-entity.class/common-base-entity.class';

@Directive({
	selector: '[commonRequired]',
	providers: [{
		provide: NG_VALIDATORS,
		useExisting: CommonRequiredDirective,
		multi: true,
	}],
})
export class CommonRequiredDirective extends CommonBaseEntityClass implements Validator, ICommonRequireValidatorContext, OnChanges {
	@Input('commonRequired') enabled: boolean;
	/**
	 * By default empty array is invalid value for commonRequired validator
	 * If this logic should be disabled and empty array is correct for some control - this input should be set to 'false'
	 */
	@Input('commonRequiredArrayEnabled') arrayRequiredEnabled: boolean;

	private control: FormControl;
	private validator = this.injector.get(CommonRequireValidator);

	ngOnChanges(changes: SimpleChanges) {
		if (changes.enabled && this.control) {
			this.control.updateValueAndValidity();
		}
	}

	validate(control: FormControl): ValidationErrors | null {
		if (!this.control) {
			this.control = control;
		}

		return this.validator.getValidator(this)(control);
	}
}
