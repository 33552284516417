import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonRequiredDirective } from './required.directive';
import { CommonModule } from '@angular/common';
import { CommonRequireValidator } from './required.validator';

@NgModule({
	imports: [
		FormsModule,
		CommonModule,
	],
	providers: [
		CommonRequireValidator,
	],
	exports: [
		CommonRequiredDirective,
	],
	declarations: [
		CommonRequiredDirective,
	],
	entryComponents: [],
})
export class CommonRequiredValidatorModule {}
