import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AuthenticationModule } from './authentication/authentication.module';
import { environment } from '../../../environments/environment';
import { commonInitApp } from '../../Common/app/app.main.init';
import { authenticationAppConfig } from './authentication/authentication.app_config.constant';

if (environment.production && !window.AppData?.IsDebugToolsEnabled) {
	enableProdMode();
}

commonInitApp(platformBrowserDynamic().bootstrapModule(AuthenticationModule), authenticationAppConfig);
