import { ICommonEventPayload } from '../events/interfaces/event-payload.interface';

export enum COMMON_CONTENT_WRAPPER_EVENT {
	RESIZE = 'COMMON_CONTENT_WRAPPER_EVENT.RESIZE',
	AFTER_RESIZE = 'COMMON_CONTENT_WRAPPER_EVENT.AFTER_RESIZE',
}

export interface ICommonContentWrapperEvent extends ICommonEventPayload {
	name: COMMON_CONTENT_WRAPPER_EVENT,
}
