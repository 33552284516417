import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
	StateDeclaration,
	TransitionService,
	StateService,
} from '@uirouter/core';
import { BehaviorSubject } from 'rxjs';
import {
	get,
	map,
} from 'lodash';

import { CommonLocaleService } from '../locale/locale.service';
import { ICommonBreadcrumb } from './interfaces/breadcrumb.interface';


const PAGE_INITIAL_TITLE_VALUE = '';

@Injectable()
export class CommonPageTitleService {
	pageTitle = new BehaviorSubject<string>(PAGE_INITIAL_TITLE_VALUE);
	breadcrumbs = new BehaviorSubject<ICommonBreadcrumb[]>([]);

	private pageInitialTitle = {
		isSet: false,
		value: PAGE_INITIAL_TITLE_VALUE,
	};
	private isStateWatching = false;
	private staticBreadcrumbs: ICommonBreadcrumb[] = [];
	private asyncBreadcrumbs: ICommonBreadcrumb[] = [];

	constructor(
		@Inject(DOCUMENT) private document: Document,
		private commonLocaleService: CommonLocaleService,
		private transitionService: TransitionService,
		private stateService: StateService,
	) {}

	public setTitle (title: string, translationParams: { [key: string]: any } = {}) {
		if (!this.pageInitialTitle.isSet) {
			this.pageInitialTitle.value = this.document.title;
			this.pageInitialTitle.isSet = true;
		}

		const translatedTitle = title && title.length ? this.commonLocaleService.instant(title, translationParams) : this.pageInitialTitle.value;

		document.title = translatedTitle;
		this.pageTitle.next(translatedTitle);
	}

	public getTitle(): BehaviorSubject<string> {
		return this.pageTitle;
	}

	public setAsyncBreadcrumbs (breadcrumbs: ICommonBreadcrumb[] = []) {
		this.asyncBreadcrumbs = breadcrumbs;
		this.calculateBreadcrumbs();
	}

	public getBreadcrumbs(): BehaviorSubject<ICommonBreadcrumb[]> {
		return this.breadcrumbs;
	}

	public startWatchingState() {
		if (this.isStateWatching) {
			return;
		}

		const module = this.getModule();

		this.setTitle(module.pageTitle);
		this.setBreadcrumbs(module.breadcrumbs);
		this.transitionService.onSuccess({}, (transition) => {
			const toState = transition.to();
			const transitionModule = this.getModule(toState);

			if (!transitionModule.isPageTitleAsync) {
				this.setTitle(transitionModule.pageTitle);
			}

			if (!transitionModule.isBreadcrumbsAsync) {
				this.setAsyncBreadcrumbs([]);
			}

			this.setBreadcrumbs(transitionModule.breadcrumbs);
		});
		this.isStateWatching = true;
	}

	private getModule(state?: StateDeclaration) {
		if (!get(state, 'name')) {
			state = this.stateService.current;
		}

		return state.data || {};
	}

	private setBreadcrumbs(breadcrumbs: ICommonBreadcrumb[] = []) {
		this.staticBreadcrumbs = map(breadcrumbs, (breadcrumb) => {
			if (get(breadcrumb, 'name.length')) {
				breadcrumb.name = this.commonLocaleService.instant(breadcrumb.name);
			}

			return breadcrumb;
		});
		this.calculateBreadcrumbs();
	}

	private calculateBreadcrumbs() {
		this.breadcrumbs.next(this.staticBreadcrumbs.concat(this.asyncBreadcrumbs));
	}
}
