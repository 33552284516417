import {
	ChangeDetectionStrategy,
	Component,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { CdkPortalOutlet } from '@angular/cdk/portal';

@Component({
	selector: 'common-popup-header-wrapper',
	templateUrl: './popup-header-wrapper.component.pug',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonPopupHeaderWrapperComponent {
	@ViewChild(CdkPortalOutlet, { static: true }) headerWrapperOutlet: CdkPortalOutlet;
}
