import { HostBinding, HostListener, Input, Directive } from '@angular/core';
import { get } from 'lodash';

import { COMMON_KEYBOARD_KEY } from '../constants/keyboard.constant';
import { CommonFocusedBaseComponent } from '../base-focused-component/common-focused-base.component';

@Directive()
export class CommonFocusedButtonBaseComponent extends CommonFocusedBaseComponent {
	@HostBinding('attr.tabindex') @Input() formTabIndex = 0;

	@HostListener('click', ['$event'])
	@HostListener('keydown.enter', ['$event'])
	@HostListener('keydown.space', ['$event'])
	@HostListener('keydown.spacebar', ['$event'])
	onClickOrEnterOrSpaceHandler($event) {
		if (get($event, 'key') === COMMON_KEYBOARD_KEY.SPACE) {
			$event.preventDefault();
		}

		this.onAction($event);
	}

	@HostListener('keydown.tab', ['$event'])
	onTabHandler($event) {
		this.onTab($event);
	}

	onAction($event) {
		// empty
	}

	onTab($event) {
		// empty
	}
}
