import { Injectable, Injector } from '@angular/core';
import { CommonBaseService } from '@CaseOne/Common/common-base-service.class/common-base-service.class';
import { CommonBootstrapService } from '@CaseOne/Common/bootstrap/bootstrap.service';
import { cloneDeep, find, filter, forEach, indexOf, isArray, isEmpty, isFunction, isString, map } from 'lodash';
import { COMMON_PERMISSIONS_OPERATOR } from '@CaseOne/Common/permissions/constants/common-permissions-operator.enum';
import { getQueryStringKeyValue, fromJson } from '@CaseOne/Common/utilities/core.service';
import { COMMON_PERMISSIONS } from '@CaseOne/Common/permissions/constants/common-permissions.enum';
import {
	ICommonPermissionsObject,
	ICommonPermissionsBootstrapPermission,
	ICommonPermissionsBootstrapSetPermission,
	ICommonPermissionsModel,
	TCommonPermissionsHasPermissionsFnPermissions,
	TCommonPermissionsHasPermissionsFnPermissionsAsObject,
	ICommonPermissionsBootstrapData
} from '@CaseOne/Common/permissions/interfaces/permissions.interfaces';

@Injectable()
export class CommonPermissionsService extends CommonBaseService {
	private commonBootstrapService = this.injector.get(CommonBootstrapService);

	private permissionsHashByNumber: Record<number, string[]> = {};
	private permissionsHashBySection: Record<string, string[]> = {};
	private permissionsObjHashByNumber: Record<number, ICommonPermissionsObject> = {};
	private permissionsObjHashBySection: Record<string, ICommonPermissionsObject> = {};
	private readonly setPermissionsBootstrap: ICommonPermissionsBootstrapSetPermission[];

	constructor (
		injector: Injector,
	) {
		super(injector);

		const bootstrap = this.commonBootstrapService.getBootstrap<ICommonPermissionsBootstrapData>();
		if (bootstrap) {
			this.setPermissionsBootstrap = cloneDeep(bootstrap.SetPermissions);
		}
	}

	hasPermission (permission: COMMON_PERMISSIONS, target: number): boolean {
		// tslint:disable-next-line:no-bitwise
		return !!(permission & target);
	}

	allHavePermission (items: ICommonPermissionsModel[], permission: string): boolean {
		return items.every((item) => {
			return item.entityPermissionsObj[permission];
		});
	}

	/**
	 * Check array or function permissions with operator (AND | OR)
	 * Examples:
	 * 	hasPermissions(['TestSection:Read', 'TestSection2:Edit'])
	 * 	hasPermissions(['{Id}:Edit'], 'AND", {Id: 1})
	 * 	hasPermissions([{ permissions: ['TestSection:Read', 'TestSection2:Edit'], operator: 'OR' }])
	 * 	hasPermissions(($injector) => {
	 *   	return $injector.get('UserDataProvider').$get().IsClient ? ['CaseBillingCodes:ReadAll'] : ['CaseBillingCodes:ReadOwn'];
	 * 	}),
	 */
	hasPermissions (
		permissions: TCommonPermissionsHasPermissionsFnPermissions,
		operator: COMMON_PERMISSIONS_OPERATOR = COMMON_PERMISSIONS_OPERATOR.AND,
		params: { [key: string]: string } = {},
	): boolean {
		if (isEmpty(permissions) && !isFunction(permissions)) {
			return true;
		}

		let result: boolean; // don't initialize
		const permissionsValue = isFunction(permissions) ? permissions(this.injector) : permissions;

		forEach<string | TCommonPermissionsHasPermissionsFnPermissionsAsObject>(permissionsValue, (p) => {
			if ((operator === COMMON_PERMISSIONS_OPERATOR.AND && result === false) || (operator === COMMON_PERMISSIONS_OPERATOR.OR && result === true)) {
				return false;
			}

			if (isString(p)) {
				const pArr = p.split(':');
				const permissionName = pArr[1];

				let sectionSysName = pArr[0];
				let sectionPermissionsArray;

				// get sectionSysName from url/state
				sectionSysName = sectionSysName.replace(/{(.+?)}/g, (m, param) => {
					return params[param] || getQueryStringKeyValue(param) || '';
				});
				sectionPermissionsArray = this.getPermissionsSysNameArrayBySection(sectionSysName);
				result = sectionPermissionsArray && indexOf(sectionPermissionsArray, permissionName) !== -1;
			} else {
				result = this.hasPermissions(p.permissions, p.operator);
			}
		});

		return result;
	}

	getCtrlPermissionsData(sectionName: string): ICommonPermissionsObject;
	getCtrlPermissionsData(sectionName: string[]): Record<string, ICommonPermissionsObject>;
	getCtrlPermissionsData(sectionNames: string | string[]): ICommonPermissionsObject | Record<string, ICommonPermissionsObject> {
		let result = {};

		if (isArray(sectionNames)) {
			forEach(sectionNames,  (sectionName) => {
				result[sectionName] = this.getPermissionsSysNameObjBySection(sectionName);
			});
		} else {
			result = this.getPermissionsSysNameObjBySection(sectionNames);
		}

		return result;
	}

	getPermissionsBySectionSysName (sectionSysName: string): number {
		const item = find(this.setPermissionsBootstrap, (i) => {
			return i.SectionSysName === sectionSysName;
		});

		return item ? item.Permissions : null;
	}

	getPermissionsSysNameArrayBySection = (sectionSysName: string): string[] => {
		if (!sectionSysName) {
			return [];
		}

		if (!this.permissionsHashBySection[sectionSysName]) {
			this.permissionsHashBySection[sectionSysName] = this._getPermissionsSysNameArrayBySection(sectionSysName) || [];
		}

		return this.permissionsHashBySection[sectionSysName];
	}

	getPermissionsSysNameObjBySection = (sectionSysName: string): ICommonPermissionsObject => {
		if (!sectionSysName) {
			return {};
		}

		if (!this.permissionsObjHashBySection[sectionSysName]) {
			this.permissionsObjHashBySection[sectionSysName] = {};

			forEach(this.getPermissionsSysNameArrayBySection(sectionSysName), (p) => {
				this.permissionsObjHashBySection[sectionSysName]['is' + p] = true;
			});
		}

		return this.permissionsObjHashBySection[sectionSysName];
	}

	getPermissionsSysNameArrayByNumber = (permissions: number): string[] => {
		if (!permissions) {
			return [];
		}

		if (!this.permissionsHashByNumber[permissions]) {
			this.permissionsHashByNumber[permissions] = this._getPermissionsSysNameArrayByNumber(permissions) || [];
		}

		return this.permissionsHashByNumber[permissions];
	}

	getPermissionsSysNameObjByNumber = (permissions: number): ICommonPermissionsObject => {
		if (!permissions) {
			return {};
		}

		if (!this.permissionsObjHashByNumber[permissions]) {
			this.permissionsObjHashByNumber[permissions] = {};

			forEach(this.getPermissionsSysNameArrayByNumber(permissions),  (p) => {
				this.permissionsObjHashByNumber[permissions]['is' + p] = true;
			});
		}

		return this.permissionsObjHashByNumber[permissions];
	}

	binaryNumberToPermissionArray(num: number): ICommonPermissionsBootstrapPermission[] {
		const permissions = this.commonBootstrapService.getBootstrap<ICommonPermissionsBootstrapPermission[]>('Permissions');

		return filter(cloneDeep(permissions), (p) => {
			// tslint:disable-next-line:no-bitwise
			return (num & p.Id) !== 0;
		});
	}

	permissionsTransformForItem<T extends ICommonPermissionsModel>(inputItem: T): T;
	permissionsTransformForItem<T extends ICommonPermissionsModel>(inputItem: T[]): T[];
	permissionsTransformForItem<T extends ICommonPermissionsModel>(inputItem: T | T[]): T | T[] {
		if (isArray(inputItem)) {
			return map(inputItem, (item) => {
				item.entityPermissionsObj = this.getPermissionsSysNameObjByNumber(item.Permissions);
				return item;
			});
		} else {
			inputItem.entityPermissionsObj = this.getPermissionsSysNameObjByNumber(inputItem.Permissions);
			return inputItem;
		}
	}

	permissionsTransformResponse = <T>(response: T): T => {
		const parsedResponse = fromJson(response);

		if (parsedResponse && parsedResponse.Result) {
			parsedResponse.Result = this.permissionsTransformForItem(parsedResponse.Result);
		}

		return parsedResponse || [];
	}

	private _getPermissionsSysNameArrayBySection (sectionSysName): string[] {
		return this._getPermissionsSysNameArrayByNumber(this.getPermissionsBySectionSysName(sectionSysName));
	}

	private _getPermissionsSysNameArrayByNumber (num: number): string[] {
		return map(this.binaryNumberToPermissionArray(num), 'SysName');
	}

}
