// New version src/Common/modules_placeholders/modules_placeholders.service.ts CommonModulesPlaceholders
// Old version src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/providers/modules_placeholders_provider.ts ModulesPlaceholders

// Provider registration and storages info about module placeholder
import { map, isFunction, get } from 'lodash';
import { Injector, Provider, Injectable } from '@angular/core';

export interface ICommonModulesPlaceholdersProvider {
	registerPlaceholder<T>(placeholder: ICommonModulesPlaceholderType<T>): ICommonModulesPlaceholdersProvider
}

export interface ICommonModulesPlaceholderType<T> {
	name: string;
	deps?: [Provider];
	oldContext?: boolean;
	placeholder: T;
}

interface IPlaceholdersList<T> {
	[key: string]: Array<ICommonModulesPlaceholderType<T>>
}

@Injectable({
	providedIn: 'root',
})
export class CommonModulesPlaceholders {
	protected modulesPlaceholdersList: IPlaceholdersList<any> = {};
	protected $injector;

	public registerOldInjector($injector) {
		this.$injector = $injector;
	}

	public registerPlaceholder<T>(placeholderObject: ICommonModulesPlaceholderType<T>): ICommonModulesPlaceholdersProvider {
		this.modulesPlaceholdersList[placeholderObject.name] = this.modulesPlaceholdersList[placeholderObject.name] || [];
		this.modulesPlaceholdersList[placeholderObject.name].push(placeholderObject);

		return this;
	}

	public getPlaceholder<T>(name: string): T[] {
		return map(this.modulesPlaceholdersList[name], this.invokePlaceholder.bind(this));
	}

	private invokePlaceholder<T>(placeholderObject: ICommonModulesPlaceholderType<T>): T {
		let placeholder = placeholderObject.placeholder;

		if (isFunction(placeholder)) {
			let deps = [];

			if (get(placeholderObject, 'deps.length')) {
				// not in constructor for downgrading support
				const injector = Injector.create({providers: []});

				deps = map(placeholderObject.deps, (dependency) => {
					if (placeholderObject.oldContext) {
						return this.$injector.get(dependency as any);
					} else {
						return injector.get(dependency as any);
					}
				});
			}

			placeholder = placeholder(...deps);
		}

		return placeholder;
	}
}
