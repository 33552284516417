import {
	FormControl,
	FormGroup,
} from '@angular/forms';
import {
	Component,
	Injector,
} from '@angular/core';
import {
	finalize,
	catchError,
} from 'rxjs/operators';
import {
	throwError,
} from 'rxjs';
import {
	includes,
	isString,
} from 'lodash';

import { CommonServerDateService } from '../../../Common/server_date/server_date.service';
import { CommonAppDataService } from '../../../Common/app_data/app_data.service';
import { CommonNotificationService } from '../../../Common/notification/notification.service';
import { CommonSecondsFormatterPipe } from '../../../Common/pipes/seconds_formatter.pipe';

import { AuthenticationUserService } from '../user/user_authentication.service';
import { AuthenticationErrorService } from '../authentication/services/authentication_error.service';
import { CommonBaseComponent } from '../../../Common/base_component/base.component';
import { UIRouter } from '@uirouter/angular';

declare interface IUser {
	login: string;
	password: string;
}

declare interface IResource {
	authState: string;
	isLinkSent: boolean;
	appName: string;
	domainName: string;
	validationCustomErrors: {},
	authFlashMessage: string
	isPhoneConfirmState: boolean;
	phone: string;
	resendDelaySeconds?: number;
	waitResendText?: string;
}

@Component({
	selector: 'authentication-login',
	templateUrl: './login.component.pug',
})
export class AuthenticationLoginComponent extends CommonBaseComponent {
	resource: IResource;
	user: IUser;

	state = {
		isLoading: false,
	};

	codeFormModel = {
		code: null,
	};

	phoneFormModel = {
		phone: null,
	};

	onChangeFieldHandler = this.authenticationErrorService.onChangeFieldHandler;

	private stopResendTimer;

	constructor (
		private commonAppDataService: CommonAppDataService,
		private authenticationUserService: AuthenticationUserService,
		private commonServerDateService: CommonServerDateService,
		private commonNotificationService: CommonNotificationService,
		private commonSecondsFormatterPipe: CommonSecondsFormatterPipe,
		private authenticationErrorService: AuthenticationErrorService,
		private uiRouter: UIRouter,
		protected injector: Injector,
	) {
		super(injector);

		this.user = {
			login: '',
			password: '',
		};

		this.resource = {
			authState: 'login',
			isLinkSent: false,
			appName: this.commonAppDataService.getDataByPath('appName'),
			domainName: this.commonAppDataService.getDataByPath('domainName'),
			validationCustomErrors: {},
			authFlashMessage: this.commonAppDataService.getDataByPath('authFlashMessage'),
			isPhoneConfirmState: false,
			phone: '',
		};

		this.commonAppDataService.setDataByPath('', 'authFlashMessage');
	}

	sendLogin (form: FormGroup): void {
		if (form.valid) {
			this.state.isLoading = true;

			this.authenticationUserService
				.login(this.user)
				.pipe(
					catchError((err) => {
						this.state.isLoading = false;
						return throwError(err);
					}),
				)
				.subscribe((response: any) => {
					if (response.Succeeded) {
						if (response.Is2FAActive) {
							if (response.IsPhoneConfirmed) {
								this.goToConfirmCode({
									isPhoneConfirm: false,
									phone: response.Phone,
									nextResendTime: response.NextAttemptTime,
								});
							} else {
								this.phoneFormModel.phone = response.Phone;
								this.resource.authState = 'add_phone';
							}

							this.state.isLoading = false;
						} else {
							this.signIn(response.ReturnUrl);
						}
					} else {
						this.state.isLoading = false;
						this.authenticationErrorService.handleSuccessResponse(response, this.resource.validationCustomErrors, form.controls.password as FormControl);
					}
				});
		}
	}

	sendPhone (form: FormGroup) {
		if (form.valid) {
			this.state.isLoading = true;

			this.authenticationUserService
				.changePhone({
					Login: this.user.login,
					Phone: this.phoneFormModel.phone,
				})
				.pipe(
					finalize(() => {
						this.state.isLoading = false;
					}),
				)
				.subscribe((response: any) => {
					if (response.IsSuccess) {
						this.goToConfirmCode({
							isPhoneConfirm: true,
							phone: response.PendingPhone,
							nextResendTime: response.NextAttemptTime,
						});
					} else {
						this.authenticationErrorService.showCustomError(response, this.resource.validationCustomErrors, form.controls.phone as FormControl);
					}
				});
		}
	}

	sendCode (form: FormGroup): void {
		if (form.valid) {
			this.state.isLoading = true;

			this.authenticationUserService[this.resource.isPhoneConfirmState ? 'confirmPhoneCode' : 'checkLoginCode']({
				Login: this.user.login,
				Code: this.codeFormModel.code,
			})
				.pipe(
					catchError((err) => {
						this.state.isLoading = false;
						return throwError(err);
					}),
				)
				.subscribe((response: any) => {
					if (response.Succeeded) {
						this.signIn(response.ReturnUrl);
					} else {
						this.state.isLoading = false;
						this.authenticationErrorService.handleSuccessResponse(response, this.resource.validationCustomErrors, form.controls.code as FormControl);
					}
				});
		}
	}

	resendCode (form: FormGroup): void {
		this.state.isLoading = true;

		this.authenticationUserService[this.resource.isPhoneConfirmState ? 'resendPhoneCode' : 'resendLoginCode']({
			Login: this.user.login,
		})
			.pipe(
				finalize(() => this.state.isLoading = false),
			)
			.subscribe((response: any) => {
				if (response.IsSuccess) {
					this.initResendTimer(response.NextAttemptTime);
				} else {
					this.authenticationErrorService.handleSuccessResponse(response, this.resource.validationCustomErrors, form.controls.code as FormControl);
				}
			});
	}

	remind (form: FormGroup): void {
		if (form.valid) {
			this.state.isLoading = true;

			this.authenticationUserService
				.remindPassword({
					login: this.user.login,
				})
				.pipe(
					finalize(() => this.state.isLoading = false),
				)
				.subscribe((response: any) => {
					const hasErrors = response.Errors && response.Errors.length;

					this.user.password = hasErrors ? '' : this.user.password;

					if (!hasErrors) {
						this.resource.isLinkSent = true;

					} else {
						if (includes(response.Errors, this.instant('authentication.actions.login.noty.account_blocked.title')) && isString(response.Errors[0])) {
							this.commonNotificationService.show('', response.Errors[0], 'error');
						} else {
							this.authenticationErrorService.showCustomError(response, this.resource.validationCustomErrors, form.controls.login as FormControl);
						}
					}
				});
		}
	}

	goToConfirmCode (params): void {
		this.resource.isPhoneConfirmState = !!params.isPhoneConfirm;
		this.resource.phone = params.phone;
		this.initResendTimer(params.nextResendTime);
		this.resource.authState = 'confirm_code';
	}

	goToLogin () {
		this.resource.authState = 'login';
		this.resource.isLinkSent = false;
	}

	backFromConfirmCode (): void {
		if (this.resource.isPhoneConfirmState) {
			this.resource.authState = 'add_phone';
		} else {
			this.goToLogin();
		}
	}

	goToRemind () {
		this.resource.authState = 'remind';
		this.resource.isLinkSent = false;
	}

	private signIn (returnUrl: string): void {
		returnUrl = returnUrl || this.getReturnUrl();
		this.redirect(returnUrl);
	}

	private redirect (url: string): void {
		const isLocalUrl = location.href.split('#')[0] === url.split('#')[0];

		url = url.replace(/&amp;/g, '&');  // back-end add &amp; in url

		// Disabled routing for defender not allowed url
		this.uiRouter.dispose();
		window.location.assign(url);

		// Run redirect for local url
		if (isLocalUrl) {
			window.location.reload();
		}
	}

	private getReturnUrl (): string {
		let returnUrl = this.commonAppDataService.getDataByPath('authenticationReturnUrl');

		// we haven't the hash in ReturnUrl, when user redirected by back-end
		// we save the current hash in authenticationReturnUrlHash before the auth application is initialized
		if (returnUrl && !returnUrl.includes('#')) {
			returnUrl += this.commonAppDataService.getDataByPath('authenticationReturnUrlHash');
		}

		return returnUrl || this.commonAppDataService.getDataByPath('appFullLocationUrl');
	}

	private initResendTimer (NextPhoneConfirmationAttemptTime: number): void {
		window.clearInterval(this.stopResendTimer);

		this.resource.resendDelaySeconds = this.getResendDelaySeconds(NextPhoneConfirmationAttemptTime);
		this.setWaitResendText();

		this.stopResendTimer = window.setInterval(() => {
			this.resource.resendDelaySeconds--;
			this.setWaitResendText();

			if (!this.resource.resendDelaySeconds) {
				window.clearInterval(this.stopResendTimer);
			}
		}, 1000);
	}


	private getResendDelaySeconds (NextPhoneConfirmationAttemptTime?: number): number {
		return !NextPhoneConfirmationAttemptTime ? 0 : Math.abs(this.commonServerDateService.getMoment().diff(this.commonServerDateService.getMoment(NextPhoneConfirmationAttemptTime), 'seconds') || 0);
	}

	private setWaitResendText (): void {
		this.resource.waitResendText = this.instant('authentication.form.confirm_code.description.timer_text', {
			time: this.commonSecondsFormatterPipe.transform(this.resource.resendDelaySeconds),
		});
	}
}
