import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { CommonValidator } from '../common-validator';
import { DATA_VALIDATION } from '@CaseOne/Common/control_validators/data_validation.constant';


export const COMMON_EMAIL_ERROR_KEY = 'email';


@Injectable({
	providedIn: 'root',
})
export class CommonEmailValidator extends CommonValidator {
	getValidator() {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!RegExp(DATA_VALIDATION.email).test(control.value) && control.value) {
				return { [COMMON_EMAIL_ERROR_KEY]: true };
			} else {
				return null;
			}
		};
	}
}
