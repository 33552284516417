// New version CommonLineToBrPipe src/Common/pipes/common-line-to-br.pipe.ts
// Old version commonLineToBr src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/filters/common_line_to_br_filter.js
import { Pipe, PipeTransform } from '@angular/core';

// Replace \r\n on <br>-tags
@Pipe({
	name: 'commonLineToBr',
})
export class CommonLineToBrPipe implements PipeTransform {
	transform(data) {
		return data ? data.replace(/(?:\r\n|\r|\n)/g, '<br />') : data;
	}
}
