import { commonBrowserService } from '../browser/common-browser.service';

export const COMMON_KEYBOARD_KEY = {
	ENTER: 'Enter',
	TAB: 'Tab',
	ARROW_DOWN: commonBrowserService.browser.ie ? 'Down' : 'ArrowDown',
	ARROW_UP: commonBrowserService.browser.ie ? 'Up' : 'ArrowUp',
	ARROW_LEFT: commonBrowserService.browser.ie ? 'Left' : 'ArrowLeft',
	ARROW_RIGHT: commonBrowserService.browser.ie ? 'Right' : 'ArrowRight',
	END: 'End',
	HOME: 'Home',
	ESCAPE: commonBrowserService.browser.ie ? 'Esc' : 'Escape',
	SPACE: commonBrowserService.browser.ie ? 'Spacebar' : ' ',
	BACKSPACE: 'Backspace',
	DELETE: commonBrowserService.browser.ie ? 'Del' : 'Delete',
	DIGIT_ONE: '1',
	DIGIT_ZERO: '0',
	MINUS: '-',
	UNDERSCORE: '_',
	EQUAL: '=',
	PLUS: '+',
	COMMA: ',',
	SEMICOLON: ';',
};
