import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ICommonEventPayload } from '../interfaces/event-payload.interface';
import { filter, map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class CommonEventsService<T = any> {
	private eventsSubject = new Subject<ICommonEventPayload<T>>();

	public emit(event: ICommonEventPayload<T>) {
		this.eventsSubject.next(event);
	}

	public on(name: string): Observable<T> {
		return this.eventsSubject
			.pipe(
				filter((event: ICommonEventPayload<T>) => event.name === name),
				map((event: ICommonEventPayload<T>) => event.payload),
			);
	}
}
