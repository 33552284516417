import { AfterViewInit, Directive } from '@angular/core';
import { AsyncSubject } from 'rxjs';

import { CommonBaseComponent } from '../../../base_component/base.component';

@Directive()
export class CommonContextMenuBaseScreenComponent extends CommonBaseComponent implements AfterViewInit {
	ngAfterViewInit$ = new AsyncSubject<void>();

	ngAfterViewInit() {
		this.ngAfterViewInit$.next();
		this.ngAfterViewInit$.complete();
	}
}
