import { NgModule } from '@angular/core';
import { CommonAppNameComponent } from './common-app-name.component';

@NgModule({
	declarations: [
		CommonAppNameComponent,
	],
	exports: [
		CommonAppNameComponent,
	],
})
export class CommonAppNameModule {}
