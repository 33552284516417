import { Injectable } from '@angular/core';
import { CommonPermissionsService } from './permissions.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CommonPermissionsParsersService {
	constructor(
		private commonPermissionsService: CommonPermissionsService,
	) {
	}

	permissionsTransformResponse<T> (observable: Observable<T>): Observable<T> {
		return observable
			.pipe(map((response) => {
				return this.commonPermissionsService.permissionsTransformResponse(response);
			}));
	}
}
