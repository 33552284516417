import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'commonEscapedBracketReplace',
})
export class CommonEscapedBracketReplace implements PipeTransform {
	transform (str: string) {
		return str ? str.replace(/&lt;/g, '<').replace(/&gt;/g, '>') : str;
	}
}
