import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {Directive, Input} from '@angular/core';
import {CommonMatchValidator} from './match.validator';
import {CommonBaseEntityClass} from '../../common-base-entity.class/common-base-entity.class';

@Directive({
	selector: '[commonMatch]',
	providers: [{
		provide: NG_VALIDATORS,
		useExisting: CommonMatchValidatorDirective,
		multi: true,
	}],
})
export class CommonMatchValidatorDirective extends CommonBaseEntityClass implements Validator {
	@Input() commonMatch: string;

	private validator = this.injector.get(CommonMatchValidator);

	validate(control: AbstractControl): ValidationErrors | null {
		const matchControl = control.root.get(this.commonMatch);

		return this.validator.getValidator({ matchControl })(control);
	}
}
