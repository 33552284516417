import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
	TranslateModule,
	TranslateLoader,
	TranslateCompiler,
} from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import localePt from '@angular/common/locales/pt';
import localeFr from '@angular/common/locales/fr';

import {
	CommonLocaleService,
} from './locale.service';
import {
	CommonLocaleHttpLoader,
} from './locale-http-loader.class';
import { CommonTranslationCompiler } from './translation_compiler.service';

declare const COMPONENT_SPECS_MODE: boolean;

registerLocaleData(localeRu);
registerLocaleData(localePt);
registerLocaleData(localeFr);

export function initCommonLocaleService (
	commonLocaleService: CommonLocaleService,
) {
	return () => {
		if (!COMPONENT_SPECS_MODE) {  // until https://github.com/angular/angular/issues/24218 is fixed
			return commonLocaleService.init();
		}
	};
}

@NgModule({
	imports: [
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: CommonLocaleHttpLoader,
				deps: [
					HttpClient,
				],
			},
			compiler: {
				provide: TranslateCompiler,
				useClass: CommonTranslationCompiler,
			},
		}),
	],
	exports: [
		TranslateModule,
	],
	providers: [
		CommonLocaleService,
		{
			provide: APP_INITIALIZER,
			useFactory: initCommonLocaleService,
			multi: true,
			deps: [
				CommonLocaleService,
			],
		},
	],
})
export class CommonLocaleModule {}
