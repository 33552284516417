// New version src/Common/pipes/common-number-with-currency-formatter.pipe.ts
// Old version src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/filters/common_number_with_currency_formatter_filter.js

import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtilitiesCore } from '../utilities/core.service';
import { CommonNAFormatPipe } from './common-na-format.pipe';
import { CommonPrettyNumberPipe } from './common-pretty-number.pipe';
import { COMMON_DEFAULT_NUMBER_CONSTANTS } from '../constants/common-default-number.constants';
import { CommonGeneralSettingsService } from '../general_settings/general_settings.service';
import {
	get,
} from 'lodash';
import { CommonAppDataService } from '../app_data/app_data.service';
import { numberToFixedDecimals } from '../utilities/number-to-fixed-decimals';
import { fixedIEEE754Format } from '../utilities/number-fixed-IEEE754-format';
import { ICommonCurrencyModel } from '../interfaces/core';

interface ICommonNumberWithCurrencyFormatterOptions {
	currency?: string | ICommonCurrencyModel,
	useCommonNAToBlankFormatter?: boolean,
	formattedNumber?: string,
	skipBracketsAndSwitch?: boolean,
}

@Pipe({
	name: 'commonNumberWithCurrencyFormatter',
})
export class CommonNumberWithCurrencyFormatterPipe implements PipeTransform {
	appData = this.commonAppDataService.getData();

	constructor (
		private utilities: CommonUtilitiesCore,
		private naFormatter: CommonNAFormatPipe,
		private prettyNumberFormatter: CommonPrettyNumberPipe,
		private commonGeneralSettingsService: CommonGeneralSettingsService,
		private commonAppDataService: CommonAppDataService,
	) {}

	transform(num: number, options: ICommonNumberWithCurrencyFormatterOptions = {}) {
		if (!this.utilities.hasValue(num)) {
			return this.naFormatter.transform(num, options.useCommonNAToBlankFormatter);
		}

		const currency = get(options, 'currency') || this.commonGeneralSettingsService.getGeneralSettings('Currency');
		const currencySymbol = currency && currency.Symbol ? currency.Symbol : currency;
		const isNegativeNumber = num < 0;
		const isNegativeValuesWithParentheses = this.appData?.IsNegativeValuesWithParentheses && !options.skipBracketsAndSwitch;
		let result;

		if (options.formattedNumber) {
			result = options.formattedNumber;
		} else {
			const fixedNumber = numberToFixedDecimals(Math.abs(num), COMMON_DEFAULT_NUMBER_CONSTANTS.DIGITS_AFTER_DECIMAL, fixedIEEE754Format);

			result = this.prettyNumberFormatter.transform(fixedNumber);
		}

		if (isNegativeNumber && isNegativeValuesWithParentheses) {
			result = `(${result})`;
		}

		if (currencySymbol) {
			result = currencySymbol + result;
		}

		if (isNegativeNumber && !isNegativeValuesWithParentheses) {
			result = '- ' + result;
		}

		return result;
	}
}
