// Old version src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/common-exception-handler.ts
// New version src/Common/error-handler.service/error-handler.service.ts

import { ErrorHandler, Injectable } from '@angular/core';
import { CommonLoggerService } from '@CaseOne/Common/logger/common-logger.service';

@Injectable()
export class CommonErrorHandlerService implements ErrorHandler {
	constructor(
		private commonLoggerService: CommonLoggerService,
	) {}

	handleError(error: ErrorEvent) {
		// Ignore cancel request error in promise
		if (!error || !error.message || error.message.indexOf('{"status":-1}') === -1) {
			console.error(error);
		}

		if (error && error.message && error.message.indexOf('Uncaught (in promise') > -1 && error.message.indexOf('{"status":-1}') > -1) {
			return;
		}
		this.commonLoggerService.logErrorEvent(error);
	}
}
