import { Injectable } from '@angular/core';
import { CommonGeneralSettingsDaysOfWeekResourceService } from './general-settings-days-of-week-resource.service';
import { CommonBaseDictionaryService } from '../../../controls/base-dictionary.service/common-base-dictionary.service';
import { ICommonGeneralSettingsDaysOfWeekModel } from '../interfaces/general-settings-days-of-week-model.interface';

@Injectable()
export class CommonGeneralSettingsDaysOfWeekDictionaryService extends CommonBaseDictionaryService<ICommonGeneralSettingsDaysOfWeekModel> {
	srcService = this.injector.get(CommonGeneralSettingsDaysOfWeekResourceService);
	name = 'GeneralSettingsDaysOfWeek';
}
